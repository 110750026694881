<template>
  <div class="ml-auto" v-if="user">


    <VaButton round v-if="userInfo" color="buttonSecondary" class="name-text" @click="$router.push('/profile')">
      <va-avatar color="primary" :src="user.photoURL" fallback-icon="person" size="24px" class="mr-2"></va-avatar>

      {{ userInfo['first_name'] }} {{ userInfo['last_name'] }}
    </VaButton>

    <VaButton class="ml-3" round v-if="userInfo" color="buttonSecondary" icon="help_outline" @click="$router.push('/contact')"></VaButton>
  </div>
</template>
<script>
import {auth} from "@/services/auth";
import firebase from "firebase/compat/app";
import router from "@/router";

export default {
  components: {},
  data: () => ({
    menu: false,
    nbItems: 0,
    user: null,
    userInfo: null,
    supportedLanguages: {
      'fr': {flag: 'fr', name: 'Français'},
      'en': {flag: 'us', name: 'English'},
    },
    selectedLang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'fr',
  }),
  methods: {
    router() {
      return router
    },
    async logout() {
      await auth.signOut()
      this.$router.push('/login')
    },
    async changeLanguage(lang) {
      this.$i18n.locale = lang;
      this.selectedLang = lang;
      localStorage.setItem('lang', lang);
    },
  },
  async mounted() {
    this.$i18n.locale = this.selectedLang
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.user = user;
        this.userInfo = await firebase.firestore().collection('users').doc(user.uid).get().then((doc) => {
          if (doc.exists) {
            return doc.data()
          } else {
            return null
          }
        })
      }
    });
  }
};
</script>
<style>
.info-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.name-text {
  color: var(--Black, #000) !important;
  text-align: center !important;
  leading-trim: both !important;
  text-edge: cap !important;
  font-family: Montserrat !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 110% !important;
}
</style>

<template>
  <div class="default-border-bottom fix-action-button" style="z-index: 2;   background: #FFFFFF; padding-top: 15px">
    <va-button icon="inventory" id="btn-action-gestion" round class="ml-5" @click="redirect('stock')" text-color="black"
               color="buttonSecondary"
               size="medium">Gestion du stock
    </va-button>

    <VaButton icon="manage_search" icon-color="black" @click="redirect('orders')" id="btn-action-gestion"
              text-color="black" color="buttonSecondary"
              class="ml-3" size="medium" round>
      Commandes
    </VaButton>

    <va-button icon="shopping_cart" class="ml-3" size="medium"
               @click="redirect('suppliers')" id="btn-action-gestion" round>
      Fournisseurs
    </va-button>

    <va-button class="ml-4" preset="plain" color="primary" icon="add_circle_outline" style="margin-top: 10px"
               @click="addItem()">Créer un fournisseur
    </va-button>
  </div>
  <div>
    <VaDataTable
        :items="items"
        :columns="columns"
        :filter="filterData"
        :row-bind="getRowBind"
        class="table"
    >
      <template #cell(actions)="{ rowIndex }">
        <VaButton
            icon="edit"
            @click="openModalToEditItemById(rowIndex)"
            round text-color="black" color="buttonSecondary"
        >Modifier
        </VaButton>
        <VaButton
            icon="delete" icon-color="#D80000"
            class="ml-3"
            @click="removeSupplier(rowIndex)"
            round text-color="black" color="buttonSecondary"
        >Supprimer
        </VaButton>
      </template>
    </VaDataTable>
  </div>

  <VaModal
      class="modal-crud"
      :model-value="!!editedItem"
      title="Ajouter un fournisseur"
      size="medium"
      @ok="editItem"
      @cancel="resetEditedItem"
      close-button
      hide-default-actions
      blur
  >
    <VaInput
        id="modal-crud"
        v-for="key in Object.keys(editedItem)"
        :key="key"
        v-model="editedItem[key]"
        class="mb-4"
        :label="columns.find((column) => column.key === key).label"
        :placeholder="'Entrer un ' + columns.find((column) => column.key === key).label"
    />
    <template #footer="{ ok, cancel }">
      <VaCardActions>
        <VaButton @click="ok" size="small" round color="primary">
          Ajouter un fournisseur
        </VaButton>
        <VaButton @click="cancel" size="small" round color="backgroundPrimary">
          Annuler
        </VaButton>
      </VaCardActions>
    </template>
  </VaModal>
</template>
<script>
import {auth} from "@/services/auth";
import firebase from "firebase/compat/app";

export default {
  components: {},
  data() {
    return {
      user: null,
      columns: [
        {key: "name", label: "Fournisseurs", sortable: true},
        {key: "email", label: "Email", sortable: true,},
        {key: "phone_number", label: "Téléphone", sortable: true, width: '200px'},
        {key: "actions", width: 300},
      ],
      filterData: "",
      items: [],
      editedItemId: null,
      editedItem: null,
      createdItem: {
        name: "",
        email: "",
        phone_number: ""
      },
    };
  },
  methods: {
    redirect(route) {
      this.$router.push('/' + route)
    },
    resetEditedItem() {
      this.editedItem = null;
      this.editedItemId = null;
    },
    addItem() {
      //   Open modal to add item
      this.editedItem = {...this.createdItem};
    },
    editItem() {
      console.log(this.editedItem);
      console.log(this.editedItemId);
      if (this.editedItemId === null) {
        firebase.firestore()
            .collection('users')
            .doc(this.user.uid)
            .collection('suppliers')
            .add(this.editedItem);
      } else {
        firebase.firestore()
            .collection('users')
            .doc(this.user.uid)
            .collection('suppliers')
            .doc(this.editedItemId)
            .set(this.editedItem);
      }


      this.resetEditedItem();
    },
    openModalToEditItemById(id) {
      this.editedItemId = this.items[id].id;
      //   remove field id
      this.editedItem = {...this.items[id]};
      delete this.editedItem.id;
    },
    removeSupplier(id) {
      this.$vaModal.init({
        title: 'Voulez-vous vraiment supprimer ce fournisseur ?', // title
        message: 'Cette action est irréversible', // message
        blur: true,
        okText: 'CONFIRMER',
        cancelText: 'ANNULER',
        onOk: () => {
          //  remove from stock
          firebase.firestore()
              .collection('users')
              .doc(this.user.uid)
              .collection('suppliers')
              .doc(this.items[id].id)
              .delete();
        },
        onCancel: () => console.log('clicked CANCEL'),
      })
    },
  },
  async mounted() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.user = user;
        firebase.firestore()
            .collection('users')
            .doc(user.uid)
            .collection('suppliers').onSnapshot((querySnapshot) => {
          console.log(querySnapshot);
          this.items = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            this.items.push(data);
          });
        });
      }
    });
  }
};
</script>
<style>
#btn-action-gestion .va-button__content {
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: 0.48px !important;
}

.modal-crud {
  .va-input {
    display: block;
  }

  .va-input-wrapper__label {
    color: black !important;
    font-weight: 600 !important;
  }

  .va-modal__title {
    color: black !important;
    font-size: 13px !important;
  }

  .va-modal__close {
    color: black !important;
  }

  .va-modal__message {
    margin-bottom: 0 !important;
  }

  .va-card__title, .va-card__content, .va-card__actions, .va-card__actions--vertical {
    padding: 0 !important;
    width: 350px !important;
  }

  .va-card__actions {
    padding: 0 !important;
  }

  .va-button__content {
    font-weight: 400 !important;
    padding: 7px !important;
  }
}
</style>


import {defineComponent} from 'vue'
import {useRouter} from "vue-router";
import {auth} from "@/main";
import firebase from "firebase/compat/app";


export default defineComponent({
  components: {},
  setup() {
    const router = useRouter(); // get a reference to our vue router

    return {
      router,
    };
  },
  data() {
    return {
      user: auth.currentUser,
    }
  },
  methods: {
    async UpdateUserAndContinue() {
      await firebase.firestore()
          .collection('authorized_users')
          .doc(auth.currentUser!.email!).get().then((doc) => {
            if (doc.exists) {
              console.log("Document data:", doc.data());
              this.router.push('/search');
            }
          });
    },


    handleLoggedIn(user) {
      // Handle the logged in user here
      console.log('User has logged in: ', user);
      this.router.push('/search');
    },
  }
})

import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import LoginView from '@/views/LoginView.vue'
import HomeView from "@/views/HomeView.vue";
import ComparatorSearchComponent from "@/components/ComparatorSearchComponent.vue";
import CartComponent from "@/components/CartComponent.vue";
import BookmarksComponent from "@/components/BookmarksComponent.vue";
import ProfileComponent from "@/components/ProfileComponent.vue";
import ConfirmAccountView from "@/views/ConfirmAccountView.vue";
import ConfirmEmailView from "@/views/ConfirmEmailView.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import AdminComponent from "@/components/AdminComponent.vue";
import WaitValidationView from "@/views/WaitValidationView.vue";
import StockComponent from "@/components/StockComponent.vue";
import OrdersComponent from "@/components/OrdersComponent.vue";
import SuppliersComponent from "@/components/SuppliersComponent.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        components: {
            main: HomeView,
            home: HomeView
        },
        children: [{
            path: '/cart',
            name: 'cart',
            components: {
                home: CartComponent
            }
        },{
            path: '/stock',
            name: 'stock',
            components: {
                home: StockComponent
            }
        },{
            path: '/orders',
            name: 'orders',
            components: {
                home: OrdersComponent
            }
        },{
            path: '/suppliers',
            name: 'suppliers',
            components: {
                home: SuppliersComponent
            }
        }, {
            path: '/bookmarks',
            name: 'bookmarks',
            components: {
                home: BookmarksComponent
            }
        }, {
            path: '/profile',
            name: 'profile',
            components: {
                home: ProfileComponent
            }
        }, {
            path: '/search',
            name: 'search',
            components: {
                home: ComparatorSearchComponent
            }
        }, {
            path: '/contact',
            name: 'contact',
            components: {
                home: ContactComponent
            }
        }, {
            path: '/admin',
            name: 'admin',
            components: {
                home: AdminComponent
            }
        }]
    },
    {
        path: '/login',
        name: 'login',
        components: {
            main: LoginView //you sidebar main component in 'a' name of routed-view
        }
    },
    {
        path: '/confirm-account',
        name: 'confirm-account',
        components: {
            main: ConfirmAccountView
        }
    }, {
        path: '/wait-validation',
        name: 'wait-validation',
        components: {
            main: WaitValidationView
        }
    },
    {
        path: '/confirm-email',
        name: 'confirm-email',
        components: {
            main: ConfirmEmailView
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router

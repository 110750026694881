<template>
  <ais-instant-search :search-client="searchClient" index-name="products">

    <div class="fix-search" style="z-index: 2; text-align: -webkit-center; background: #f8f7fd">
      <ais-search-box placeholder="Rechercher un produit (Nom, Marque, Réf. fabricant)" autofocus :class-names="{
        'ais-SearchBox': 'search',
        'ais-SearchBox-submit': 'submit',
        'ais-SearchBox-input': 'input-search',
        'ais-SearchBox-submitIcon': 'input-icon',
        'ais-SearchBox-form': 'input-form',
        }"/>

    </div>

    <div class="left-panel-search result-table-header" style="overflow-y: auto;max-height: 500px">

      <ais-refinement-list
          attribute="brand"
          searchable
          show-more
      >
        <template
            v-slot="{
                items,
                isShowingMore,
                isFromSearch,
                refine,
                toggleShowMore,
                searchForItems,
                }">
          <div style="padding-left: 8px; padding-right: 8px; height: 38px">
            <VaInput @input="searchForItems($event.currentTarget.value)" type="search" autocorrect="off"
                     v-model="searchBrand"
                     autocapitalize="off" autocomplete="off" spellcheck="false" required="" maxlength="512"
                     background="backgroundSecondary"
                     aria-label="Rechercher" placeholder="Rechercher une marque"
                     id="brand-input">
              <template #prependInner>
                <VaIcon
                    name="search"
                    color="secondary"
                />
              </template>
            </VaInput>
          </div>
          <ul style="padding-left: 8px; font-size: 14px" class="mt-2">
            <li v-if="isFromSearch && !items.length">Aucun résultat</li>
            <li v-for="item in items" :key="item.value">
              <va-checkbox
                  v-model="item.isRefined"
                  class="mt-2"
                  :label="item.value + ' (' + item.count + ')'"
                  @click.prevent="refine(item.value)"
              />

            </li>
          </ul>
          <va-button
              @click="toggleShowMore"
              size="small" round preset="secondary"
              class="mt-2 ml-4">
            {{ !isShowingMore ? 'Voir plus' : 'Voir moins' }}
          </va-button>

        </template>
      </ais-refinement-list>
      <ais-configure :hitsPerPage="50"/>
    </div>

    <div style="display: grid" class="mr-3">
      <ais-state-results>
        <template v-slot="{ results: { hits, query } }">
          <ais-hits v-if="hits.length > 0">
            <template v-slot="{ items }">
              <table class="va-table w-100" id="result-table">
                <colgroup>
                  <col span="1" style="width: 65%;">
                  <col span="1" style="width: 15%;">
                  <col span="1" style="width: 15%;">
                  <col span="1" style="width: 5%;">
                </colgroup>
                <thead class="table-header result-table-header">
                <tr>
                  <th>Produit</th>
                  <th>Marque</th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <th style="height:0.15px; padding:0.15px; background:rgb(0 0 0 / 10%)"></th>
                  <th style="height:0.15px; padding:0.15px; background:rgb(0 0 0 / 10%)"></th>
                  <th style="height:0.15px; padding:0.15px; background:rgb(0 0 0 / 10%)"></th>
                  <th style="height:0.15px; padding:0.15px; background:rgb(0 0 0 / 10%)"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in items" :key="item.objectID" class="border-bottom">
                  <td class="cell-product">
                    <va-icon @click="clickOnStar(item.objectID)"
                             :name="isStarred(item.objectID)" color="primary" class="mr-4 pointer"></va-icon>

                    <img :src="item.img_url" class="img-product pointer" fit="scale-down"
                         @click="showProduct(item)"
                         v-if="item.img_url"/>
                    <img src="../assets/icons/no_image.svg"
                         class="img-product" fit="cover"
                         v-else/>
                    <span class="ml-3 product-title">
                        {{ item.name }}
                      <p class="product-description">Réf. fabricant: {{ item.ref_brand }}</p>
                        <p v-if="showDescription[item.objectID]"
                           class="product-description" v-html="item.description"></p>
                        <span
                            class="show-description pointer"
                            @click="showDescription[item.objectID] = !showDescription[item.objectID]">{{
                            !showDescription[item.objectID] ? 'Voir plus' : 'Voir moins'
                          }}</span>
                     </span>
                  </td>
                  <td class="brand">{{ item.brand }}</td>
                  <!--                    <td class="brand" v-if="showPriceColumn()">-->
                  <!--                      <span v-if="item['min_price']">{{ item['min_price'] }}€</span>-->
                  <!--                      <va-popover v-else message="Prix indisponible" placement="top" style="margin-left: 25px;">-->
                  <!--                        <img src="../assets/icons/No_price.svg">-->
                  <!--                      </va-popover>-->
                  <!--                    </td>-->
                  <td style="text-align: center">
                    <!--                    <va-counter :min="0" v-model="currentCart.productsWaitUpdate[item.objectID]" width="100px"-->
                    <!--                                manual-input-->
                    <!--                                @update:modelValue="updateCart(item.objectID)" color="secondary"/>-->

                    <va-button :color="isInStock(item) ? '#ffb14f' : 'warning'" icon="inventory" icon-color="#ffffff" class="p-1" text-color="#ffffff"
                               size="small"
                               @click="addToStock(item)" round>{{isInStock(item) ? 'Gérer le stock' : 'Ajouter au stock'}}
                    </va-button>
                  </td>

                  <td style="text-align: center">
                    <va-button color="primary" icon="compare_arrows" size="small" class="p-1"
                               @click="addToCart(item.objectID)" round>Comparer
                    </va-button>
                  </td>
                </tr>
                </tbody>
              </table>

            </template>
          </ais-hits>
          <div v-else>
            Pas de résultats pour {{ query }}.
          </div>
        </template>
      </ais-state-results>


      <ais-pagination class="mt-3 mb-5" :show-first="false" :show-last="false" :class-names="{
    'ais-Pagination-link': 'pagination-link'}"/>

    </div>

  </ais-instant-search>

  <favorite-modal
      ref="favoriteModalRef"
      :product-to-save="productToSave"
      :user-bookmarks="userBookmarks"
      :starred-products="starredProducts"
      @updateUserBookmarks="handleUserBookmarksUpdate"
      @productFavorited="handleProductFavorited"
  />
  <add-stock-modal
      ref="addStockModal"
      :item="editedStockItem"
  />
  <va-modal v-model="showProductModal" blur hide-default-actions>
    <a :href="product_detail.img_url" target="_blank">
      <va-image :src="product_detail.img_url" class="img-detail">
      </va-image>
    </a>
  </va-modal>

</template>
<script>
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/algolia-min.css';
import firebase from "firebase/compat/app";
import {auth} from "@/services/auth";
import axios from "axios";
import FavoriteModal from "@/components/modals/FavoriteModal.vue";
import AddStockModal from "@/components/modals/AddStockModal.vue";


export default {
  components: {AddStockModal, FavoriteModal},
  data() {
    return {
      searchClient: algoliasearch(
          `${process.env.VUE_APP_ALGOLIA_APP_ID}`,
          `${process.env.VUE_APP_ALGOLIA_API_KEY}`
      ),
      currentCart: {quantityProducts: {}, needsUpdate: {}, productsWaitUpdate: {}, products: {}},
      starredProducts: {},
      editedStockItem: {},
      showDescription: {},
      userBookmarks: [],
      currentStock: [],
      productToSave: '',
      newBookmarkName: '',
      searchBrand: '',
      currentDescription: '',
      product_detail: {},
      showModal: false,
      showProductModal: false,
      showModalNew: false,
      userInfo: null,
    };
  },
  methods: {
    addToCart(id) {
      firebase.firestore()
          .collection('users')
          .doc(auth.currentUser.uid)
          .collection('cart')
          .where(firebase.firestore.FieldPath.documentId(), '==', id)
          .get()
          .then((querySnapshot) => {
            console.log(querySnapshot.empty);
            if (querySnapshot.empty) {
              firebase.firestore()
                  .collection('users')
                  .doc(auth.currentUser.uid)
                  .collection('cart')
                  .doc(id)
                  .set({
                    quantity: 1
                  }).then(() => {
                this.$vaToast.init({message: 'Produit ajouté au comparateur', position: 'bottom-right'})

              });
            } else {
              querySnapshot.forEach((doc) => {
                firebase.firestore()
                    .collection('users')
                    .doc(auth.currentUser.uid)
                    .collection('cart')
                    .doc(doc.id)
                    .update({quantity: doc.data().quantity + 1})
                    .then(() => {
                      console.log('updated cart');
                      this.$vaToast.init({message: 'Quantité mise à jour', position: 'bottom-right'})
                    });
              });
            }
          });

    },
    handleUserBookmarksUpdate(newValue) {
      this.userBookmarks.push(newValue);
    }, handleProductFavorited(bookmark) {
      this.starredProducts[this.productToSave] = bookmark;
    },
    showPriceColumn() {
      return this.userInfo && (typeof this.userInfo['show_price_column'] === 'undefined' || this.userInfo['show_price_column'] === true)
    },
    updateCart(product_id) {
      if (this.currentCart.productsWaitUpdate[product_id] === 0) {
        delete this.currentCart.productsWaitUpdate[product_id];
        // add product to user cart in firestore
        firebase.firestore()
            .collection('users')
            .doc(auth.currentUser.uid)
            .collection('cart')
            .doc(product_id).delete();
        this.$vaToast.init({message: 'Produit supprimé du comparateur', position: 'bottom-right', color: 'danger'})
      } else {
        // axios.get(`${process.env.VUE_APP_BACKEND_URL}/get-price?product_id=${objectID}&site=dentalclick`,)
        // add product to user cart in firestore
        firebase.firestore()
            .collection('users')
            .doc(auth.currentUser.uid)
            .collection('cart')
            .doc(product_id)
            .set({
              quantity: this.currentCart.productsWaitUpdate[product_id],
            });
        if (this.currentCart.productsWaitUpdate[product_id] === 1) {
          axios.get(`${process.env.VUE_APP_BACKEND_URL}/refresh-price?product_id=${product_id}`,).then((response) => {
            console.log(response.data);
          });

          this.$vaToast.init({message: 'Produit ajouté au comparateur', position: 'bottom-right'})
        }
      }
    },


    showProduct(product) {
      if (product) {
        this.product_detail = product;
        this.showProductModal = true;
      }
    },
    clickOnStar(objectID) {
      this.productToSave = objectID;
      if (this.isStarred(objectID) === 'favorite') {
        console.log('click is star', objectID)
        firebase.firestore()
            .collection('users')
            .doc(auth.currentUser.uid)
            .collection('bookmarks')
            .doc(this.starredProducts[objectID])
            .update({product_ids: firebase.firestore.FieldValue.arrayRemove(this.productToSave)});
        this.$vaToast.init({message: 'Produit retiré des favoris', position: 'bottom-right'});
        delete this.starredProducts[objectID];
        return 'favorite_border';
      }
      console.log('click is not star', objectID, this.isStarred(objectID))

      this.$refs.favoriteModalRef.showModal = true;
    },
    isStarred(objectID) {
      if (this.starredProducts[objectID]) {
        return 'favorite';
      }
      return 'favorite_border';
    },
    redirectContent(path) {
      this.$router.push(path);
    },
    isInStock(item) {
      return this.currentStock.includes(item.objectID);

    },
    addToStock(item) {
      // get best price
      this.editedStockItem = {
        name: item.name,
        ref_brand: item.ref_brand,
        brand: item.brand,
        supplier: "Comparapex",
        unitPrice: 0,
        stock: 0,
        alertStock: 0,
        img_url: item.img_url,
        action: "Ajouter au stock",
        isCustomProduct: false,
        id: item.objectID,
        step: "create"
      };
      this.showModalSearchProduct = false;
      this.$refs.addStockModal.editedItem = this.editedStockItem;
      this.$refs.addStockModal.searchItemInStock();
      this.$refs.addStockModal.showModal = true;
    },

    async getCart() {
      this.currentCart = {quantityProducts: {}, needsUpdate: {}, productsWaitUpdate: {}, products: {}}; // reset cart
      const cart = await firebase.firestore()
          .collection('users')
          .doc(auth.currentUser.uid)
          .collection('cart')
          .get();

      cart.forEach((doc) => {
        this.currentCart.quantityProducts[doc.id] = doc.data().quantity;
        this.currentCart.productsWaitUpdate[doc.id] = doc.data().quantity;
      });
    },
    async getStock() {
      const stock = await firebase.firestore()
          .collection('users')
          .doc(auth.currentUser.uid)
          .collection('stock')
          .get();

      stock.forEach((doc) => {
        this.currentStock.push(doc.id);
      });
    },
  },
  async mounted() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        firebase.firestore()
            .collection('users')
            .doc(user.uid)
            .collection('cart').onSnapshot(() => {
          this.getCart();
        });

        firebase.firestore()
            .collection('users')
            .doc(user.uid)
            .collection('stock').onSnapshot(() => {
          this.getStock();
        });

        this.userInfo = (await firebase.firestore()
            .collection('users')
            .doc(user.uid)
            .get()).data();

        const bookmarks = await firebase.firestore()
            .collection('users')
            .doc(user.uid)
            .collection('bookmarks').get();
        bookmarks.forEach((doc) => {
          this.userBookmarks.push(doc.id);
          doc.data()['product_ids'].forEach((product_id) => {
            this.starredProducts[product_id] = doc.id;
          });
        });
      }
    });
  },
};
</script>

<style>

.brand {
  color: var(--gris-fonc-858585, #858585);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.32px;
}

.product-description {
  color: var(--gris-fonc-858585, #858585);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
}

.product-title {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.32px;
}

.show-description {
  color: var(--gris-fonc-858585, #858585);
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.32px;
  text-decoration-line: underline;
}

body {
  font-family: sans-serif;
}

.left-panel-search {
  float: left;
  width: 20%;
  margin-right: 10px;
  margin-left: 20px;
}

.img-product {
  width: 70px !important;
  height: 70px !important;
  border-radius: 15px;
  background: var(--blanc-ffffff, #FFF);
}

.cell-product {
  display: flex;
  align-items: center;
}

.va-table td {
  vertical-align: middle !important;
}

.ais-Pagination-item--selected .ais-Pagination-link {
  background-color: var(--va-primary) !important;
  color: white !important;
}

.ais-Pagination-link {
  color: var(--va-primary) !important;
  border-color: var(--va-primary) !important;
}

.img-detail {
  width: 400px;
  height: 400px;
}

.search {
  background: var(--blanc-ffffff, #FFF);
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 40%;
  min-width: 550px;
  border-radius: 100px !important;

}

::placeholder {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E) !important;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-search {
  height: 38px;
  border: 0;
  padding: 0rem 60px;
  border-radius: 100px !important;

}

.submit {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.input-icon {
  width: 24px;
  height: 24px;
  margin-left: 17px;
  flex-shrink: 0;
  filter: invert(37%) sepia(93%) saturate(5317%) hue-rotate(243deg) brightness(90%) contrast(91%);
}

.brand-input-search {
  height: 61px;
  border: 0;
  //border-bottom: 1px solid rgb(0 0 0 / 10%);
  background: var(--blanc-ffffff, #F7F6FD);
  //padding: 0.3rem 1rem;
  padding-left: 10px;
  margin-bottom: 0px;
  border-radius: 0;
  font-size: 12px;
}

.va-checkbox .va-checkbox__square {
  border-radius: 7px !important;
  border: solid 1px var(--gris-fonc-858585, #858585) !important;
}

.va-checkbox .va-checkbox__label {
  color: var(--gris-fonc-858585, #858585);
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.32px;
}

.va-checkbox--selected .va-checkbox__label {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.32px;
}

.brand-submit {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.brand-input-icon {
  display: none;
}

.showmore {
  display: flex;
  width: 184px;
  height: 30px;
  padding: 15px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7.5px;
  flex-shrink: 0;
  border-radius: 45px !important;
  background: var(--mauve-6246-dc, #6246DC);
  color: var(--blanc-ffffff, #FFF);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
}

.va-table th {
  border: 0 !important;
  font-size: 11px !important;
  vertical-align: middle !important;
  border-collapse: collapse; /* Don't collapse */

  border-bottom: 1px solid rgb(0 0 0 / 0%) !important;
  border-left: 1px solid rgb(0 0 0 / 0%) !important;
}

.table-header {
  background: var(--blanc-ffffff, #F7F6FD);
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  height: 38px;
}

.custom-filter-item {
  background: var(--mauve-6246-dc, #F7F6FD);
  color: black;
  border-radius: 0 !important;
  padding-top: 9px !important;
  display: block !important;
}

.pagination-link {
  border: 0;
  color: var(--gris-fonc-858585, #858585) !important;
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.28px;
}


.va-modal__dialog {
  border-radius: 30px !important;
}


tr.border-bottom {
  border-bottom: 10px solid #73af00;
  padding: 50px;
  margin: 50px;
}

.default-border-bottom {
  border-bottom: 1px solid rgb(0 0 0 / 10%)
}

.default-border {
  border: 1px solid rgb(0 0 0 / 10%)
}

.result-table-header {
  position: -webkit-sticky;
  position: sticky;
  top: 138px;
  z-index: 1;
}

.fix-search {
  position: -webkit-sticky;
  position: sticky;
  top: 78px;
  z-index: 1;
  background: #f8f7fd;
  height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.fix-filters {
  position: -webkit-sticky;
  position: sticky;
  top: 136px;
  z-index: 1;
  background: #f8f7fd;
}

.custom-current {
  width: auto !important;
}

.custom-text-filter {
  color: black !important;
}

.input-form {
  border-radius: 100px !important;
  border: 1px solid var(--va-primary) !important;
}

#brand-input::placeholder {
  font-size: 11px !important;
  padding-left: 0px !important;
  margin-left: 0 !important;
}

#brand-input.ais-SearchBox-input {
  padding: 0 !important;
  padding-left: 3px !important;
}

#brand-input.va-input-wrapper__field {
  border-radisu: 0 !important;
}

table tfoot {
  font-size: 14px;
}
</style>

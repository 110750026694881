<template>
  <va-card class=" m-5">
    <va-card-title>Contactez nous</va-card-title>
    <va-card-content>
      <div v-if="user" style="text-align: center">
        <va-input :disabled="true" v-model="user.email" class="mt-3 w-25" label="Email"></va-input>
        <br>
        <va-select
            v-model="contactReason"
            :options="options"
            class="mt-3 w-25"
        />
        <br>
        <va-textarea v-model="commentContact" class="mt-3 w-25" label="Commentaire" type="textarea"
                     placeholder="Veuillez renseigner le lien du produit"></va-textarea>
        <br>
        <va-button class="btn btn-outline-primary va-text-center mt-3" @click="submit()">
          Envoyer
        </va-button>
      </div>
    </va-card-content>
    <va-card-actions>
      <ul class="footer_nav_bottom">
        <li class="footer_nav_item_bottom">
          <a class="h9_12 footer_nav_link_bottom" href="https://comparapex.fr/conditions-utilisation"
             aria-label="Conditions d'utilisation">Conditions
            d'utilisation</a>
        </li>
        <li class="footer_nav_item_bottom">
          <a class="h9_12 footer_nav_link_bottom" href="https://comparapex.fr/mentions-legales"
             aria-label="Mentions légales">Mentions
            légales</a>
        </li>
        <li class="footer_nav_item_bottom">
          <a class="h9_12 footer_nav_link_bottom" href="https://comparapex.fr/politique-confidentialite"
             aria-label="Politique de confidentialité ">Politique de confidentialité </a>
        </li>
      </ul>
    </va-card-actions>
  </va-card>

</template>
<script>
import {auth} from "@/services/auth";
import firebase from "firebase/compat/app";

export default {
  components: {},
  data: () => ({
    user: auth.currentUser,
    contactReason: 'Produit manquant',
    options: [
      "Produit manquant",
      "Prix erroné",
      "Autres"
    ],
    commentContact: '',
  }),
  methods: {
    async submit() {
      const db = firebase.firestore();
      const contactRef = db.collection('contact').doc();
      await contactRef.set({
        email: this.user.email,
        contactReason: this.contactReason,
        commentContact: this.commentContact,
        date: new Date()
      });
      this.commentContact = '';
      this.$vaToast.init({message: 'Votre message a bien été envoyé !', position: 'bottom-right', color: 'success'});
      this.$router.push('/search');

    }
  },
  async mounted() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.user = user;
      }
    });
  }
};
</script>

<template>
  <div class="default-border-bottom fix-action-button" style="z-index: 2;   background: #f8f7fd; padding-top: 20px">
    <span class="ml-5 section-title">Favoris</span>
    <va-button class="ml-3" preset="plain" color="primary" icon="add_circle_outline" style="margin-top: 3px;"
               @click="showModalNew=true">Ajouter une liste
    </va-button>
  </div>
  <div class="m-3">

    <va-collapse
        v-for="(collapse, index) in collapses"
        :key="index"
        v-model="value[index]"
        :header="collapse.title">

      <template #header="{ value, attrs, iconAttrs, text }">
        <div v-bind="attrs" class="header-accordion mt-3">
          <va-button @click="addToCart(collapse.content)" round size="large" color="backgroundSecondary"
                     border-color="buttonSecondary" class="ml-5 mr-3">
            <VaIcon
                name="va-arrow-down"
                :class="value ? 'rotate-90' : ''"
                v-bind="iconAttrs"
            />
          </va-button>

          <div class="ml-2">
            <span class="title-accordion">{{ text }}</span><br>
            <span class="subtitle-accordion">{{ collapse.content.length }} produits</span>
          </div>
          <va-spacer></va-spacer>
          <va-button @click="addToCart(collapse.content)" round size="medium" color="buttonSecondary"
                     class="mr-3" icon="compare_arrows">
            Ajouter la liste au comparateur
          </va-button>

          <va-button @click="removeBookmark(collapse)" round size="medium" color="buttonSecondary" icon="delete"
                     class="mr-5" icon-color="danger">
            Supprimer la liste
          </va-button>


        </div>
      </template>

      <template #body>
        <div style="display: flex; background: white">
          <table class="va-table w-100">
            <colgroup>
              <col span="1" style="width: 70%;">
              <col span="1" style="width: 15%;">
              <col span="1" style="width: 15%;">
            </colgroup>
            <tbody>
            <tr v-for="product in collapse.content" :key="product">
              <td class="cell-product pl-4">
                <img @click="removeProductFromBookmark(product)" src="../assets/icons/Trash_Icons.svg"
                     class="mr-4 pointer"/>
                <img :src="product.img_url" class="img-product pointer" fit="scale-down"
                     @click="showProduct(product)"
                     v-if="product.img_url"/>
                <img src="https://fakeimg.pl/600x400?text=No+image"
                     class="img-product" fit="cover"
                     v-else/>
                <span class="ml-3 product-title">
                        {{ product.name }}
                  
                  <p class="product-description">Réf. fabricant: {{ product.ref_brand }}</p>
                        <p v-if="showDescription[product.id]"
                           class="product-description" v-html="product.description"></p>
                        <span
                            class="show-description pointer"
                            @click="showDescription[product.id] = !showDescription[product.id]">{{
                            !showDescription[product.id] ? 'Voir plus' : 'Voir moins'
                          }}</span>
                     </span>
              </td>
              <td class="brand">{{ product.brand }}</td>
              <td>
                <va-counter :min="0" v-model="currentCart.productsWaitUpdate[product.id]" width="100px" manual-input
                            @update:modelValue="updateCart(product.id)" color="secondary"/>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </template>
    </va-collapse>

  </div>
  <va-modal v-model="showModalNew" blur size="small" close-button hide-default-actions
            backgroundColor="background-secondary">
    <h3 class="fav-name mb-4">
      Créer une liste
    </h3>
    <p class="mb-4">
      <va-input v-model="newBookmarkName" label="Nom de la liste"/>
    </p>
    <div style="text-align: center;">
      <va-button class="mt-3 btn-create-fav" @click="saveBookmark" color="primary">Sauvegarder</va-button>
    </div>
  </va-modal>

  <va-modal v-model="showProductModal" blur hide-default-actions>
    <a :href="product_detail.img_url" target="_blank">
      <va-image :src="product_detail.img_url" class="img-detail">
      </va-image>
    </a>
  </va-modal>
</template>
<script>
import {auth} from "@/services/auth";
import firebase from "firebase/compat/app";

export default {
  components: {},
  data() {
    return {
      value: [],
      collapses: [],
      product_detail: {},
      showDescription: {},
      newBookmarkName: '',
      showModalNew: false,
      showProductModal: false,
      currentCart: {quantityProducts: {}, needsUpdate: {}, productsWaitUpdate: {}, products: {}},
    };
  },
  methods: {
    showProduct(product) {
      if (product) {
        this.product_detail = product;
        this.showProductModal = true;
      }
    },
    removeBookmark(collapse) {
      firebase.firestore()
          .collection('users')
          .doc(auth.currentUser.uid)
          .collection('bookmarks')
          .doc(collapse.title)
          .delete();
      this.collapses = this.collapses.filter((c) => c.title !== collapse.title);
    },
    removeProductFromBookmark(product) {
      firebase.firestore()
          .collection('users')
          .doc(auth.currentUser.uid)
          .collection('bookmarks')
          .doc(this.collapses.find((collapse) => collapse.content.includes(product)).title)
          .update({
            product_ids: firebase.firestore.FieldValue.arrayRemove(product.id)
          });
      this.collapses.find((collapse) => collapse.content.includes(product)).content = this.collapses.find((collapse) => collapse.content.includes(product)).content.filter((p) => p.id !== product.id);
    },

    async addToCart(products) {
      for (const product of products) {
        await firebase.firestore()
            .collection('users')
            .doc(auth.currentUser.uid)
            .collection('cart')
            .doc(product.id)
            .set({
              quantity: 1
            });
      }
    }, updateCart(product_id) {
      console.log(product_id)
      if (this.currentCart.productsWaitUpdate[product_id] === 0) {
        delete this.currentCart.productsWaitUpdate[product_id];
        // add product to user cart in firestore
        firebase.firestore()
            .collection('users')
            .doc(auth.currentUser.uid)
            .collection('cart')
            .doc(product_id).delete();
        this.$vaToast.init({message: 'Produit supprimé du comparateur', position: 'bottom-right', color: 'danger'})
      } else {
        // add product to user cart in firestore
        firebase.firestore()
            .collection('users')
            .doc(auth.currentUser.uid)
            .collection('cart')
            .doc(product_id)
            .set({
              quantity: this.currentCart.productsWaitUpdate[product_id],
            });
        if (this.currentCart.productsWaitUpdate[product_id] === 1)
          this.$vaToast.init({message: 'Produit ajouté au comparateur', position: 'bottom-right'})
      }
    },
    saveBookmark() {
      firebase.firestore()
          .collection('users')
          .doc(auth.currentUser.uid)
          .collection('bookmarks')
          .doc(this.newBookmarkName)
          .set({
            product_ids: [],
          });
      this.collapses.push({title: this.newBookmarkName, content: []});
      this.showModalNew = false;
      this.newBookmarkName = '';
    },
    clickOnStar(objectID) {
      this.productToSave = objectID;
      if (this.isStarred(objectID) === 'star') {
        firebase.firestore()
            .collection('users')
            .doc(auth.currentUser.uid)
            .collection('bookmarks')
            .doc(this.starredProducts[objectID])
            .update({product_ids: firebase.firestore.FieldValue.arrayRemove(this.productToSave)});
        this.$vaToast.init({message: 'Produit retiré des favoris', position: 'bottom-right'})

        delete this.starredProducts[objectID];

        return 'star_border';
      }
      this.showModal = !this.showModal
    }, async getCart() {
      this.currentCart = {quantityProducts: {}, needsUpdate: {}, productsWaitUpdate: {}, products: {}}; // reset cart
      const cart = await firebase.firestore()
          .collection('users')
          .doc(auth.currentUser.uid)
          .collection('cart')
          .get();

      cart.forEach((doc) => {
        this.currentCart.quantityProducts[doc.id] = doc.data().quantity;
        this.currentCart.productsWaitUpdate[doc.id] = doc.data().quantity;
      });
    },
  },
  async mounted() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.user = user;
        firebase.firestore()
            .collection('users')
            .doc(user.uid)
            .collection('cart').onSnapshot(() => {
          this.getCart();
        });
        const bookmarks = await firebase.firestore()
            .collection('users')
            .doc(user.uid)
            .collection('bookmarks').get();
        bookmarks.forEach((doc) => {
          this.collapses.push({title: doc.id, content: []});
          this.value.push(false);

          doc.data()['product_ids'].forEach(async (product_id) => {
            let product = await firebase.firestore()
                .collection('products')
                .doc(product_id).get();
            console.log(product.data())
            //retrieve collapse by name and push content
            if (product.data()) {
              let product_content = product.data()
              product_content['id'] = product_id
              this.collapses.find((collapse) => collapse.title === doc.id).content.push(product_content);
            }
          });
        });
      }
    });
  }
};
</script>
<style>
.va-collapse__header {
  background: var(--blanc-ffffff, #FFF) !important;
  margin-bottom: 32px;
}

.va-accordion .va-collapse:first-child .va-collapse__header {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.va-accordion .va-collapse:last-child .va-collapse__header {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.va-collapse__header__text {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
}

.btn-add-cart {
  display: flex;
  width: 240px;
  height: 40px;
  padding: 20px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 60px !important;
  background: var(--orange-ffab-59, #FFAB59) !important;
}

.va-collapse__header {
  box-shadow: none !important;
}

.btn-create-cart {
  display: flex;
  width: 240px;
  height: 40px;
  padding: 20px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 60px !important;
  color: var(--mauve-6246-dc, #6246DC) !important;
}

#newBookmark .va-button__content {
  color: var(--mauve-6246-dc, #6246DC) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: 0.48px !important;
}

#newBookmark .va-button__content {
  color: var(--blanc-ffffff, #FFF) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: 0.48px !important;
}

.fav-name {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.va-collapse__body-wrapper--bordered {
  border: none !important;
}
</style>

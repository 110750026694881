<template>
  <div class="default-border-bottom fix-action-button" style="z-index: 2;   background: #FFFFFF; padding-top: 15px">
    <va-button icon="inventory" id="btn-action-gestion" round class="ml-5"
               size="medium">Gestion du stock
    </va-button>

    <!--    <VaButton icon="manage_search" icon-color="black" @click="redirect('orders')" id="btn-action-gestion"-->
    <!--              text-color="black" color="buttonSecondary"-->
    <!--              class="ml-3" size="medium" round>-->
    <!--      Commandes-->
    <!--    </VaButton>-->

    <!--    <va-button icon="shopping_cart" icon-color="black" class="ml-3" size="medium" text-color="black"-->
    <!--               color="buttonSecondary"-->
    <!--               @click="redirect('suppliers')" id="btn-action-gestion" round>-->
    <!--      Fournisseurs-->
    <!--    </va-button>-->

    <va-button class="ml-4" preset="plain" color="primary" icon="add_circle_outline" style="margin-top: 10px"
               @click="showModalSearchProduct = true">Ajouter un produit au stock
    </va-button>
    <VaInput
        v-model="filterData"
        placeholder="Rechercher un produit"
        class="w-full mr-5" style="float: right"
    />

    <va-button icon="upload_file" icon-color="#ffffff" text-color="#ffffff" class="mr-3"
               color="primary" round @click="exportPDF()" style="float: right" id="btn-export">
      Exporter en PDF
    </va-button>
  </div>
  <div>
    <VaDataTable
        :items="items"
        :columns="columns"
        :filter="filterData"
        :filter-method="filterFunction"
        :row-bind="getRowBind"
        @row:click="handleClick"
        class="table"
        clickable
        id="stockTable"
    >
      <template #cell(name)="{ value, row }">
        <div class="cell-product">
          <img :src="row.rowData.img_url" class="img-product pointer" fit="scale-down"
               v-if="row.rowData.img_url"/>
          <span class="ml-3 product-title">
          {{ value }}
          <p class="product-description">Marque: {{ row.rowData.brand }}</p>
          <p class="product-description">Réf. fabricant: {{ row.rowData.ref_brand }}</p>
        </span>
        </div>
      </template>
      <template #cell(stock)="{  row }">
        <div style="text-align: center">
          {{ row.rowData.stock }}
        </div>
      </template>
      <template #cell(action)="{ row }">
        <div style="text-align: center">
          <va-button icon="add" icon-color="#ffffff" class="mr-2 pr-1" size="small" color="primary"
                     @click="updateStock(row.rowData, 1)" round>
            Ajouter
          </va-button>
          <va-button icon="remove" icon-color="#ffffff" size="small" color="warning" text-color="#ffffff" class="pr-1"
                     @click="updateStock(row.rowData, -1)" round>
            Retirer
          </va-button>
        </div>
      </template>
      <template #cell(isLowStock)="{ row }">
        <div style="text-align: center">
          <VaChip size="small" color="danger" v-if="row.rowData.stock <= row.rowData.alertStock">
            Stock bas (seuil: {{ row.rowData.alertStock }})
          </VaChip>
        </div>
      </template>
      <template #cell(value)="{ row }">
        <div style="text-align: center">
          {{ (row.rowData.unitPrice * row.rowData.stock).toFixed(2) }}€
        </div>
      </template>
      <template #cell()="{ row }">

        <va-button size="small" color="primary" class="p-1" v-if="!row.rowData.isCustomProduct"
                   @click="addToCart(row.rowData)" round>
          Ajouter au comparateur
        </va-button>
      </template>
    </VaDataTable>
  </div>
  <VaModal
      class="modal-crud"
      :model-value="showModalSearchProduct"
      @cancel="resetEditedItem"
      title="Ajouter un produit au stock"
      size="medium"
      close-button
      hide-default-actions
      blur
  >

    <ais-instant-search :search-client="searchClient" index-name="products">
      <div style="text-align: -webkit-center; display: flex">

        <ais-search-box placeholder="Rechercher un produit (Nom, Marque, Réf. fabricant)" autofocus :class-names="{
        'ais-SearchBox': 'search',
        'ais-SearchBox-submit': 'submit',
        'ais-SearchBox-input': 'input-search',
        'ais-SearchBox-submitIcon': 'input-icon',
        'ais-SearchBox-form': 'input-form',
        }"/>
        <va-button icon="add" icon-color="#ffffff" size="small" text-color="#ffffff" class="ml-3"
                   color="primary" round @click="createProduct()">
          Créer un produit
        </va-button>

      </div>
      <ais-state-results>
        <template v-slot="{ results: { hits, query } }">
          <ais-hits v-if="hits.length > 0">
            <template v-slot="{ items }">
              <table class="va-table w-100" id="result-table">
                <colgroup>
                  <col span="1" style="width: 70%;">
                  <col span="1" style="width: 15%;">
                </colgroup>
                <thead class="">
                <tr>
                  <th>Produit</th>
                  <th>Marque</th>
                </tr>
                <tr>
                  <th style="height:0.15px; padding:0.15px; background:rgb(0 0 0 / 10%)"></th>
                  <th style="height:0.15px; padding:0.15px; background:rgb(0 0 0 / 10%)"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in items" :key="item.objectID" class="border-bottom pointer"
                    @click="selectProduct(item)">
                  <td class="cell-product">
                    <img :src="item.img_url" class="img-product" fit="scale-down"
                         v-if="item.img_url"/>
                    <img src="../assets/icons/no_image.svg"
                         class="img-product" fit="cover"
                         v-else/>
                    <span class="ml-3 product-title">
                          {{ item.name }}
                        <p class="product-description">Réf. fabricant: {{ item.ref_brand }}</p>
                       </span>
                  </td>
                  <td class="brand">{{ item.brand }}</td>
                </tr>
                </tbody>
              </table>

            </template>
          </ais-hits>
          <div v-else>
            Pas de résultats pour {{ query }}.
          </div>
        </template>
      </ais-state-results>


    </ais-instant-search>
  </VaModal>

  <add-stock-modal
      ref="addStockModal"
      :item="editedItem"
  />
</template>
<script>
import {auth} from "@/services/auth";
import firebase from "firebase/compat/app";
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/algolia-min.css';
import AddStockModal from "@/components/modals/AddStockModal.vue";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'

let specialElementHandlers = {
  // element with id of "bypass" - jQuery style selector
  '.no-export': function (element, renderer) {
    // true = "handled elsewhere, bypass text extraction"
    return true;
  }
};
export default {
  components: {AddStockModal},
  data() {
    return {
      searchClient: algoliasearch(
          `${process.env.VUE_APP_ALGOLIA_APP_ID}`,
          `${process.env.VUE_APP_ALGOLIA_API_KEY}`
      ),
      user: null,
      showModalAddStock: false,
      showModalSearchProduct: false,
      selectedProduct: null,
      editedItemId: null,
      editedItem: null,
      defaultItem: {
        name: "",
        ref_brand: "",
        brand: "",
        supplier: "",
        unitPrice: 0,
        stock: 0,
        alertStock: 0,
      },

      columns: [
        {key: "name", label: "Produit", sortable: true, sortingFn: this.strSorting},
        {key: "category", label: "Catégorie", sortable: true, width: '150px', sortingFn: this.strSorting},
        {key: "stock", label: "Quantité en stock", sortable: true, width: '150px', sortingFn: this.intSorting},
        {key: "action", label: "Ajouter/Retirer du stock", sortable: false, width: '200px'},
        {key: "isLowStock", label: "État du stock", sortable: true, width: '200px', sortingFn: this.boolSorting},
        {key: "value", label: "Valeur", sortable: true, width: '100px', sortingFn: this.intSorting},
        {key: "", label: "", sortable: false, width: '150px'},
      ],
      filterData: "",
      items: []
    };
  },
  methods: {
    exportPDF() {
      // Create a new instance of jsPDF
      const doc = new jsPDF();
      // Define the headers for the table
      const headers = ["Produit", "Quantité en stock", "État du stock", "Valeur du stock"];

      // Define the data for the table
      const data = this.items.map(item => [
        item.name,
        item.stock,
        item.isLowStock ? "Stock bas" : "Stock OK",
        item.value + "€"
      ]);

      // Add the table to the PDF
      doc.autoTable({
        head: [headers],
        body: data,
      });

      // Add the date and time to the PDF - date formatting DD-MM-YYYY
      const filename = "etat_stock_" + new Date().toLocaleDateString().split('/').reverse().join('-') + ".pdf";

      // Save the PDF
      doc.save(filename);
    },
    boolSorting(a, b) {
      console.log(a, b);
      if (a === b) return 0;
      return a ? -1 : 1;
    },
    strSorting(a, b) {
      console.log(a, b);

      if (a === b) return 0;
      if (a === undefined) return 1;
      if (b === undefined) return -1;
      return a.localeCompare(b);
    },
    intSorting(a, b) {
      console.log(a, b);
      return a - b;
    },
    redirect(route) {
      this.$router.push('/' + route)
    },
    handleClick(event) {
      if (['INPUT', 'I'].includes(event.event.target.tagName) || ['add', 'remove', 'removeadd', 'add Ajouter ', "remove Retirer ", " Ajouter au comparateur "].includes(event.event.target.textContent)) {
        return;
      }

      console.log(event);
      this.editedItem = event.item;
      this.editedItemId = event.item.id;
      this.showModalAddStock = true;

      this.selectedProduct = event.item;
      this.editedItem = {
        name: event.item.name,
        ref_brand: event.item.ref_brand,
        brand: event.item.brand,
        supplier: event.item.supplier,
        unitPrice: event.item.unitPrice,
        stock: event.item.stock,
        alertStock: event.item.alertStock,
        img_url: event.item.img_url,
        action: "Ajouter au stock",
        isCustomProduct: event.item.isCustomProduct,
        id: event.item.id,
        value: event.item.unitPrice * event.item.stock,
        isLowStock: event.item.stock <= event.item.alertStock,
        step: "update"
      };

      this.$refs.addStockModal.editedItem = this.editedItem;
      this.$refs.addStockModal.showModal = true;

    },

    resetEditedItem() {
      this.editedItem = null;
      this.editedItemId = null;
      this.showModalAddStock = false;
      this.showModalSearchProduct = false;
    },
    updateStock(item, qty) {
      if (item.stock + qty < 0) {
        return;
      }
      firebase.firestore()
          .collection('users')
          .doc(this.user.uid)
          .collection('stock')
          .doc(item.id)
          .update({
            stock: item.stock + qty,
            value: (item.unitPrice * (item.stock + qty)),
            isLowStock: (item.stock + qty) <= item.alertStock
          })
    },
    getRowBind(row) {
      if (row.stock <= row.alertStock) {
        return {
          class: ["highlight-row"]
        };
      }
    },
    createProduct() {
      this.editedItem = {
        name: '',
        ref_brand: '',
        brand: '',
        supplier: "",
        unitPrice: 0,
        stock: 0,
        alertStock: 0,
        img_url: '',
        action: "Ajouter au stock",
        isCustomProduct: true,
        id: null,
        step: "create"
      };

      this.showModalSearchProduct = false;
      this.$refs.addStockModal.editedItem = this.editedItem;
      this.$refs.addStockModal.showModal = true;
    },
    selectProduct(item) {
      // get best price
      this.selectedProduct = item;
      this.editedItem = {
        name: item.name,
        ref_brand: item.ref_brand,
        brand: item.brand,
        supplier: "Comparapex",
        unitPrice: 0,
        stock: 0,
        alertStock: 0,
        img_url: item.img_url,
        action: "Ajouter au stock",
        isCustomProduct: false,
        id: item.objectID,
        step: "create"
      };
      this.showModalSearchProduct = false;
      this.$refs.addStockModal.editedItem = this.editedItem;
      this.$refs.addStockModal.searchItemInStock();
      this.$refs.addStockModal.showModal = true;
    },
    addToCart(item) {
      firebase.firestore()
          .collection('users')
          .doc(this.user.uid)
          .collection('cart')
          .where(firebase.firestore.FieldPath.documentId(), '==', item.id)
          .get()
          .then((querySnapshot) => {
            console.log(querySnapshot.empty);
            if (querySnapshot.empty) {
              firebase.firestore()
                  .collection('users')
                  .doc(this.user.uid)
                  .collection('cart')
                  .doc(item.id)
                  .set({
                    quantity: 1
                  }).then(() => {
                this.$vaToast.init({message: 'Produit ajouté au comparateur', position: 'bottom-right'})

              });
            } else {
              querySnapshot.forEach((doc) => {
                firebase.firestore()
                    .collection('users')
                    .doc(this.user.uid)
                    .collection('cart')
                    .doc(doc.id)
                    .update({quantity: doc.data().quantity + 1})
                    .then(() => {
                      console.log('updated cart');
                      this.$vaToast.init({message: 'Quantité mise à jour', position: 'bottom-right'})
                    });
              });
            }
          });

    },
    filterFunction(item) {
      if (item === undefined) return false;
      if (!this.filterData) return true;
      // find item with name
      for (let i in this.items) {
        if (this.items[i].name.toLowerCase() === item.toString().toLowerCase()) {
          return this.items[i].name.toLowerCase().includes(this.filterData.toLowerCase()) || this.items[i].brand.toLowerCase().includes(this.filterData.toLowerCase()) || (this.items[i].category !== undefined && this.items[i].category.toLowerCase().includes(this.filterData.toLowerCase()))  || this.items[i].ref_brand.toLowerCase().includes(this.filterData.toLowerCase());
        }
      }

    }
  },
  async mounted() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.user = user;
        firebase.firestore()
            .collection('users')
            .doc(user.uid)
            .collection('stock').onSnapshot((querySnapshot) => {
          this.items = [];
          querySnapshot.forEach((doc) => {
            this.items.push(doc.data());
          });
        });
      }
    });
  }
};
</script>
<style>
#btn-action-gestion .va-button__content {
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: 0.48px !important;
}

#btn-export .va-button__content {
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: 0.48px !important;
}

.highlight-row {
  background-color: #fff2f2;
}

.table {
  th {
    border: 1px solid var(--va-background-border);
    background-color: #F7F6FD;
  }

  tbody {
    tr {
      border: 1px solid var(--va-background-border);

      td {
        border: 1px solid var(--va-background-border);

        height: 3rem;
        white-space: normal;
      }
    }
  }
}

.va-input-wrapper--disabled {
  opacity: 0.8 !important;
}
</style>

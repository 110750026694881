<template>
  <div class="default-border-bottom title-section" style="z-index: 2;   background: #f8f7fd;">
    <span class="ml-5 section-title">Mon compte</span>
  </div>
  <div style="background: white; height: 150px; padding-top: 30px" class="default-border-bottom">
    <div v-if="user" class="row justify-space-evenly">
      <div class="flex flex-col xs2">
        <div class="item">
          <img class="img-profile"
               :src="user.photoURL ? user.photoURL : 'https://fakeimg.pl/600x400?text=No+image'"
          />
        </div>
      </div>
      <div class="flex flex-col xs3 content-profile">
        <div class="item">
          <span class="profile-title mb-3">Email</span>
          <va-input class="mt-3 w-100" :disabled="true" v-model="user.email"></va-input>
        </div>
      </div>
      <div class="flex flex-col xs3 content-profile">
        <div class="item">
          <span class="profile-title">Prénom/Nom</span>
          <va-input class="mt-3 w-100" v-if="userInfo" :disabled="true"
                    :model-value="userInfo.first_name+' '+userInfo.last_name"></va-input>
        </div>
      </div>
      <div class="flex flex-col xs2 content-profile">
        <div class="item">
<!--          on click redirect to https://billing.stripe.com/p/login/dR67ti4WN9JecHS5kk -->
          <va-button type="button" class="mt-4" href="https://billing.stripe.com/p/login/dR67ti4WN9JecHS5kk" target="_blank">
            Gérer mon abonnement
          </va-button>
        </div>
      </div>
      <div class="flex flex-col xs2 content-profile">
        <div class="item">
          <va-button type="button" class="mt-4" color="danger" @click="logout()">
            Se déconnecter
          </va-button>
        </div>
      </div>
    </div>
  </div>
  <div class="default-border-bottom title-section" style="z-index: 2;   background: #f8f7fd;">
    <span class="ml-5 section-title">Préférences</span>
  </div>
  <div class="row p-3" style="background: white">
    <div class="flex flex-col md6">
      <div class="item">
        <div v-if="user">

          <div class="setting-title mb-3">
<!--            <img src="../assets/icons/Select_Icons.svg" class="menu-icon">-->
            <va-icon name="compare_arrows" color="primary" />
            <span style="font-weight: 600">Sites à comparer</span>
          </div>

          <va-checkbox v-for="site in availableSites" :key="site" v-model="site.checked" :label="site.name"
                       class="m-2"></va-checkbox>

          <div class="setting-title mb-3 mt-5">
            <va-icon name="favorite_outline" color="primary" /><span style="font-weight: 600">Site préféré</span>
          </div>

          <va-select v-if="userInfo" v-model="userInfo['favorite_site']" placeholder="Choisir un site préféré" clearable
                     :options="availableSites.map((site) => site.name)"
                     class="m-2"></va-select>

          <div v-if="userInfo && userInfo['is_demo_account']">
            <div class="setting-title mb-3 mt-5">
              <img src="../assets/icons/Select_Icons.svg" class="menu-icon"><span>Personnalisation</span>
            </div>

            <va-checkbox v-model="userInfo['show_price_column']"
                         label="Affichage colonne prix"
                         class="m-2"></va-checkbox>

          </div>

        </div>
      </div>
    </div>

    <div class="flex flex-col md6">
      <div class="item">
        <div v-if="userInfo" class="">
          <div class="setting-title mb-3"><img src="../assets/icons/Discount_Icons.svg" class="menu-icon"><span style="font-weight: 600">Promotions GACD</span>
          </div>
          <div class="row">
            <div class="flex flex-col xs4 promotion-title" style="min-width: 177px">Marques GACD<br>
              <va-counter :manualInput="true" style="text-align: center"
                          v-model="userInfo['gacd_brands']" :min="0"
                          :max="100" class="m-2" width="105px">
              </va-counter>
              %
            </div>


            <div class="flex flex-col xs4 promotion-title" style="min-width: 177px">Marques nationales<br>
              <va-counter :manualInput="true" style="text-align: center"
                          v-model="userInfo['gacd_national']" :min="0"
                          :max="100" class="m-2" width="105px">
              </va-counter>
              %

            </div>


            <div class="flex flex-col xs4 promotion-title" style="min-width: 177px">Marques exclusives<br>
              <va-counter :manualInput="true" style="text-align: center"
                          v-model="userInfo['gacd_exclusive']" :min="0"
                          :max="100" class="m-2" width="105px">
              </va-counter>
              %

            </div>

          </div>
          <br>
          <div class="setting-title mb-3"><img src="../assets/icons/Discount_Icons.svg" class="menu-icon"><span style="font-weight: 600">Promotions MegaDental</span>
          </div>
          <div class="row">
            <div class="flex flex-col xs4 promotion-title" style="min-width: 177px">Contrat Catalogue<br>
              <va-input :manualInput="true" style="text-align: center; width: 118px" mask="numeral"
                        input-class="va-text-right"
                        v-model="userInfo['megadental_contrat_catalogue']" :min="0" class="m-2">
                <template #appendInner>
                  €
                </template>
              </va-input>

            </div>


            <div class="flex flex-col xs4 promotion-title" style="min-width: 177px">CTT Catalogue Offert<br>
              <va-input :manualInput="true" style="text-align: center; width: 118px" mask="numeral"
                        input-class="va-text-right"
                        v-model="userInfo['megadental_ctt_catalogue']" :min="0" class="m-2">
                <template #appendInner>
                  €
                </template>
              </va-input>
            </div>

            <div class="flex flex-col xs4 promotion-title" style="min-width: 177px">Contrat PDT DE Offert<br>
              <va-input :manualInput="true" style="text-align: center; width: 118px" mask="numeral"
                        input-class="va-text-right"
                        v-model="userInfo['megadental_contrat_pdt']" :min="0" class="m-2">
                <template #appendInner>
                  €
                </template>
              </va-input>
            </div>

          </div>
          <br>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100 pb-5 default-border-bottom" style="text-align: center; background: white">
    <va-button v-on:click="updateUserInfo">Sauvegarder</va-button>
    <va-button class="ml-4" border-color="primary" preset="secondary" v-on:click="updateUserInfo"
               id="cancel">
      Annuler
    </va-button>
  </div>
</template>
<script>
import {auth} from "@/services/auth";
import firebase from "firebase/compat/app";

export default {
  components: {},
  data: () => ({
    user: auth.currentUser,
    userInfo: null,
    availableSites: [],
    product_detail: {},
  }),
  methods: {
    showProduct(product) {
      if (product) {
        this.product_detail = product;
        this.showProductModal = true;
      }
    },
    async logout() {
      await auth.signOut()
      this.$router.push('/login')
    },
    async getAvailableSites() {
      const sites = await firebase.firestore()
          .collection('configuration')
          .doc('sites')
          .get();

      this.availableSites = [];
      console.log(this.userInfo)
      sites.data()['availableSites'].forEach((doc) => {
        this.availableSites.push({'name': doc, 'checked': this.userInfo['sites'].includes(doc)});
      });
    },
    updateUserInfo() {
      if (!this.userInfo['megadental_contrat_catalogue'])
        this.userInfo['megadental_contrat_catalogue'] = 0
      if (!this.userInfo['megadental_ctt_catalogue'])
        this.userInfo['megadental_ctt_catalogue'] = 0
      if (!this.userInfo['megadental_contrat_pdt'])
        this.userInfo['megadental_contrat_pdt'] = 0
      let discount_megadental = (1 - (parseInt(this.userInfo['megadental_contrat_catalogue']) / (parseInt(this.userInfo['megadental_contrat_catalogue']) + parseInt(this.userInfo['megadental_ctt_catalogue']))))
      discount_megadental = Math.round(discount_megadental * 100) / 100;
      if (isNaN(discount_megadental) || discount_megadental === 1)
        discount_megadental = 0

      console.log('discount_megadental', discount_megadental)

      firebase.firestore()
          .collection('users')
          .doc(this.user.uid)
          .update({
            'gacd_exclusive': this.userInfo['gacd_exclusive'],
            'gacd_national': this.userInfo['gacd_national'],
            'gacd_brands': this.userInfo['gacd_brands'],
            'favorite_site': this.userInfo['favorite_site'] ? this.userInfo['favorite_site'] : null,
            'megadental_contrat_catalogue': parseInt(this.userInfo['megadental_contrat_catalogue']),
            'megadental_ctt_catalogue': parseInt(this.userInfo['megadental_ctt_catalogue']),
            'megadental_contrat_pdt': parseInt(this.userInfo['megadental_contrat_pdt']),
            'show_price_column': this.userInfo['show_price_column'] ? this.userInfo['show_price_column'] : false,
            'discount_megadental': discount_megadental ? discount_megadental : 0,
            'sites': this.availableSites.filter((site) => site.checked).map((site) => site.name)
          })
      this.$vaToast.init({message: 'Vos préférences ont été enregistrées!', position: 'bottom-left', color: 'success'});
    }
  },
  async mounted() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.user = user;
        this.userInfo = (await firebase.firestore()
            .collection('users')
            .doc(this.user.uid)
            .get()).data();
        this.userInfo['megadental_contrat_catalogue'] = this.userInfo['megadental_contrat_catalogue'] ? this.userInfo['megadental_contrat_catalogue'].toString() : 0
        this.userInfo['megadental_ctt_catalogue'] = this.userInfo['megadental_ctt_catalogue'] ? this.userInfo['megadental_ctt_catalogue'].toString() : 0
        this.userInfo['megadental_contrat_pdt'] = this.userInfo['megadental_contrat_pdt'] ? this.userInfo['megadental_contrat_pdt'].toString() : 0
        await this.getAvailableSites()
      }
    });
  }
};
</script>
<style>
.img-profile {
  border-radius: 15px;
  background: var(--mauve-clair-f-8-f-9-fd, #F8F9FD);
  width: 80px;
  height: 80px;
  flex-shrink: 0;
}

.content-profile {
  align-self: center;
}

.title-profile {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-family: Montserrat;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
}


.setting-title {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
}

.profile-title {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-family: Montserrat;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.promotion-title {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#cancel .va-button__content {
  color: var(--mauve-6246-dc, #6246DC) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: 0.48px !important;
}

.title-section {
  z-index: 1;
  height: 65px;
  padding-top: 20px;
}
</style>

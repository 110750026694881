<template>
  <div class="row mb-4 pt-4">
    <img src="../assets/img/logo.png" class="flex flex-column xs2"/>
    <h2 class="flex flex-column xs8  h1-login" style="align-self: center;">{{ $t('welcome') }}</h2>
  </div>


  <button type="button" @click="signInGoogle" class="btn btn-outline-secondary w-100 va-text-center">
    <img src="../assets/img/icone-google.svg" class="px-2 align-middle" height="20"/>
    {{ $t('login_google') }}
  </button>

  <va-divider class="mt-4">
    <span class="px-2">OU</span>
  </va-divider>
  <va-form
      class="mt-5"
      tag="form"
      @submit.prevent="login">
    <div class="mb-4">
      <label class="form-label va-text-left title-login">Email</label>
      <va-input
          v-model="email"
          type="email"
          class="w-100"
          color="secondary"
          :placeholder=" $t('label_login') "
      />
    </div>
    <div class="mb-4">
      <label class="form-label va-text-left title-login">{{ $t('label_password') }}</label>
      <va-input
          v-model="password"
          type="password"
          class="w-100 mb-2"
          color="secondary"
          :placeholder="$t('label_sentence_password')"
      />
      {{ $t('password_forgot') }} <a @click="resetPassword()" class="pointer" style="color: #FFAB59">{{ $t('password_reset') }}</a>
    </div>
    <p v-if="errMsg" class="text-danger mb-3">{{ errMsg }}</p>


    <va-button
        class="w-100 mt-3 va-text-center" color="secondary"
        type="submit">
      {{ $t('button_login') }}
    </va-button>
  </va-form>
  <p class="mt-3 pb-5 va-text-center sub-login">
    {{ $t('no_account') }} <a href="#" @click="changeMode">{{ $t('sign_in') }}</a>
  </p>
</template>

<script>
import {ref} from "vue";
import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import axios from "axios";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";

export default {
  setup() {
    const {t} = useI18n({useScope: 'global'})
    const router = useRouter(); // get a reference to our vue router

    return {t, router}
  },
  data() {
    return {
      email: '',
      password: '',
      errMsg: ref('')
    }
  },
  methods: {
    async resetPassword() {
      if (!this.email) return this.errMsg = 'Veuillez entrer votre email'
      try {
        await firebase.auth().sendPasswordResetEmail(this.email);
        console.log('Successfully sent email!');
        return this.errMsg = 'Un email vous a été envoyé pour réinitialiser votre mot de passe'
      } catch (error) {
        console.error(error)
      }
    },
    async login() {
      try {
        firebase.auth().signInWithEmailAndPassword(this.email, this.password).then((user) => {
          console.log('Successfully logged in!');
          console.log(user);
          this.$emit('logged-in', user)
        }).catch(error => {
          switch (error.code) {
            case 'auth/invalid-email':
              this.errMsg = this.t('invalid-email')
              console.log(this.errMsg)
              break
            case 'auth/user-not-found':
              this.errMsg = this.t('user-not-found')
              console.log(this.errMsg)
              break
            case 'auth/wrong-password':
              this.errMsg = this.t('wrong-password')
              console.log(this.errMsg)
              break
            default:
              this.errMsg = this.t('error-auth')
              console.log(this.errMsg)
              break
          }
        });
      } catch (error) {
        console.error(error)
      }
    },
    async signInGoogle() {
      firebase
          .auth()
          .signInWithPopup(new firebase.auth.GoogleAuthProvider())
          .then((data) => {
            console.log('Successfully logged in!');
            console.log(data);

            const {additionalUserInfo: {isNewUser}} = data;
            if (isNewUser) {
              axios.post(`${process.env.VUE_APP_BACKEND_URL}/create-user`, {uid: data.user.uid}).then((response) => {
                console.log(response);
                this.router.push('/confirm-account');
                return;
              }).catch((error) => {
                console.log(error);
              });

            } else {
              this.$emit('logged-in', data)
            }
          })
          .catch(error => {
            console.log(error);
            switch (error.code) {
              case 'auth/invalid-email':
                this.errMsg = this.t('invalid-email')
                console.log(this.errMsg)
                break
              case 'auth/user-not-found':
                this.errMsg = this.t('user-not-found')
                console.log(this.errMsg)
                break
              case 'auth/wrong-password':
                this.errMsg = this.t('wrong-password')
                console.log(this.errMsg)
                break
              default:
                this.errMsg = this.t('error-auth')
                console.log(this.errMsg)
                break
            }
          });
    },
    changeMode() {
      console.log('changeMode');
      this.$emit('changeMode');
    }
  }
}

</script>
<style>
.sub-login {
  color: var(--gris-fonc-858585, #858585);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.32px;
}

.h1-login {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
}
</style>

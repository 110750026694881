// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/img/optimization_desktop_v2.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".custom-div{width:90%;@media (min-width:992px){width:600px}}.va-card--outlined{border:none!important;box-shadow:none!important}.bg-img{position:absolute;width:100%;height:100%;top:0;right:1px;z-index:-1;padding-left:10px;margin-left:-10px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:no-repeat;background-position:100%}.title-login{color:var(--noir-0-e-0-e-0-e,#0e0e0e);font-family:Montserrat;font-size:15px;font-style:normal;font-weight:500;line-height:normal}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

<template>
  <!--  display badge dev mode on top of all-->
<!--  <div v-if="env.startsWith('dev')" class="dev-mode-badge">-->
<!--    <va-badge color="danger" text="DEV MODE" size=""/>-->
<!--  </div>-->
  <router-view name="main"/>
</template>

<script setup>
import {ref} from 'vue' // used for conditional rendering
import firebase from 'firebase/compat/app'

const isLoggedIn = ref(true)
// runs after firebase is initialized
firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    isLoggedIn.value = true // if we have a user
  } else {
    isLoggedIn.value = false // if we do not
  }
})
import {useColors} from "vuestic-ui";

const {applyPreset} = useColors();
let theme = localStorage.getItem('theme');
if (theme)
  applyPreset(theme);
else {
  applyPreset('light');
  localStorage.setItem('theme', 'light');
}
const env = process.env.NODE_ENV;
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@400;500;600&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#app {
  height: 100vh;
  font-family: 'Montserrat', sans-serif;
}

.va-card-resume-scoring-component {
  min-width: 300px;
}

.dev-mode-badge {
  position: absolute;
  top: 0px;
  left: 20px;
  font-size: 2em;
  font-weight: bold;
  z-index: 1000;
}

.footer-link {
  font-size: 12px;
  color: dimgrey;
  text-align: center;
}

.fix-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.va-checkbox__square {
  border: solid 0.0625rem var(--va-primary) !important;
}
</style>

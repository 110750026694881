<template>
  <div class="row mb-4 pt-4">
    <img src="../assets/img/logo.png" class="flex flex-column xs2"/>
    <h2 class="flex flex-column xs8  h1-login" style="align-self: center;">{{ $t('register_title') }}</h2>
  </div>
  <button type="button" @click="registerWithGoogle" class="btn btn-outline-secondary w-100 va-text-center">
    <img src="../assets/img/icone-google.svg" class="px-2 align-middle" height="20"/>
    {{ $t('login_google') }}
  </button>

  <va-divider class="mt-4">
    <span class="px-2">OU</span>
  </va-divider>
  <va-form
      class="mt-5"
      tag="form"
      @submit.prevent="register">
    <div class="mb-4">
      <label class="form-label va-text-left title-login">Email</label>
      <va-input
          v-model="email"
          type="email"
          class="w-100"
          color="secondary"
          :placeholder=" $t('label_login') "
      />
    </div>
    <div class="mb-4">
      <label class="form-label va-text-left title-login">{{ $t('label_password') }}</label>
      <va-input
          v-model="password"
          type="password"
          class="w-100"
          color="secondary"
          :placeholder="$t('label_sentence_password')"
      />
      <p v-if="errMsg" class="text-danger mt-3">{{ errMsg }}</p>

    </div>
    <va-button
        class="w-100 mt-3 va-text-center" color="secondary"
        type="submit">
      {{ $t('register_title') }}
    </va-button>
  </va-form>
  <p class="mt-3 pb-5 va-text-center sub-login">
    {{ $t('already_account') }} <a href="#" @click="changeMode">{{ $t('button_login') }}</a>
  </p>
</template>

<script>
import firebase from "firebase/compat/app";
import {ref} from "vue";
import axios from 'axios';
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";

export default {
  setup() {
    const {t} = useI18n({useScope: 'global'})
    const router = useRouter(); // get a reference to our vue router

    return {t, router}
  },
  data() {
    return {
      email: '',
      password: '',
      errMsg: ref('')
    }
  },
  methods: {
    async register() {
      try {
        firebase.auth().createUserWithEmailAndPassword(this.email, this.password).then((user) => {
          user.user.sendEmailVerification()

          axios.post(`${process.env.VUE_APP_BACKEND_URL}/create-user`, {uid: user.user.uid}).then((response) => {
            console.log(response);
            this.router.push('/confirm-email');
            return;
          }).catch((error) => {
            console.log(error);
          });

          this.$emit('logged-in', user)
        }).catch(error => {
          console.log(error)
          switch (error.code) {
            case 'auth/invalid-email':
              this.errMsg = this.t('invalid-email')
              console.log(this.errMsg)
              break
            case 'auth/user-not-found':
              this.errMsg = this.t('user-not-found')
              console.log(this.errMsg)
              break
            case 'auth/wrong-password':
              this.errMsg = this.t('wrong-password')
              console.log(this.errMsg)
              break
            case 'auth/email-already-in-use':
              this.errMsg = 'Cette adresse email est déjà utilisée'
              console.log(this.errMsg)
              break
            default:
              this.errMsg = this.t('error-auth')
              console.log(this.errMsg)
              break
          }
        });
      } catch (error) {
        console.error(error)
      }
    },
    async registerWithGoogle() {
      try {
        firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider()).then((user) => {
              const {additionalUserInfo: {isNewUser}} = user;
              console.log(isNewUser ? "This user just registered" : "Existing User");

              if (isNewUser) {
                axios.post(`${process.env.VUE_APP_BACKEND_URL}/create-user`, {uid: this.user.user.uid}).then((response) => {
                  console.log(response);
                  this.router.push('/confirm-account');
                  return;
                }).catch((error) => {
                  console.log(error);
                });

              }

              this.$emit('logged-in', user)
            }
        ).catch(error => {
          switch (error.code) {
            case 'auth/invalid-email':
              this.errMsg = this.t('invalid-email')
              console.log(this.errMsg)
              break
            case 'auth/user-not-found':
              this.errMsg = this.t('user-not-found')
              console.log(this.errMsg)
              break
            case 'auth/wrong-password':
              this.errMsg = this.t('wrong-password')
              console.log(this.errMsg)
              break
            default:
              this.errMsg = this.t('error-auth')
              console.log(this.errMsg)
              break
          }
        });
      } catch
          (error) {
        console.error(error)
      }
    },
    changeMode() {
      this.$emit('changeMode')
    }
  }
}
</script>

<template>
  <div class="default-border-bottom fix-action-button" style="z-index: 2;   background: #FFFFFF; padding-top: 15px">
    <va-button @click="redirect('stock')" icon="inventory" id="btn-action-gestion" round class="ml-5" text-color="black" color="buttonSecondary"
               size="medium">Gestion du stock
    </va-button>

    <VaButton icon="manage_search" @click="redirect('orders')" id="btn-action-gestion"
              class="ml-3" size="medium" round>
      Commandes
    </VaButton>

    <va-button icon="shopping_cart" icon-color="black" class="ml-3" size="medium" text-color="black" color="buttonSecondary"
               @click="redirect('suppliers')" id="btn-action-gestion" round>
      Fournisseurs
    </va-button>

    <va-button class="ml-3" preset="plain" color="primary" icon="add_circle_outline" style="margin-top: 10px"
               @click="showModalNew=true">Ajouter
    </va-button>
  </div>
  <div class="m-3">
    Coming soon...
  </div>

</template>
<script>
import {auth} from "@/services/auth";
import firebase from "firebase/compat/app";

export default {
  components: {},
  data() {
    return {
      user: null,
    };
  },
  methods: {
    redirect(route) {
      this.$router.push('/' + route)
    },
  },
  async mounted() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.user = user;
      }
    });
  }
};
</script>
<style>
#btn-action-gestion .va-button__content {
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: 0.48px !important;
}
</style>

<template>
  <div class="menu-icon menu-item pointer" v-if="!showDropdown()">
    <va-badge
        overlap
        offset="0px"
        style="--va-badge-text-wrapper-border-radius: 50%;"
        :text="nbItems"
        placement="right"
        textColor="#FFFFFF"
        color="secondary">
      <img src="../assets/icons/Compare_Icons.svg" class="menu-icon">
    </va-badge>
    <span class="menu-text" :class="getActiveClass('cart')">Comparer</span>
  </div>
  <va-dropdown :auto-placement="true" trigger="click" :close-on-content-click="false" @open="addBlurBody()"
               @close="removeBlur()" v-if="showDropdown()">
    <template #anchor>
      <!--      <div class="menu-icon menu-item" @click="$router.push('/cart')">-->
      <div class="menu-icon menu-item">
        <va-badge
            overlap
            offset="0px"
            style="--va-badge-text-wrapper-border-radius: 50%;"
            :text="nbItems"
            placement="right"
            textColor="#FFFFFF"
            color="secondary">
          <img src="../assets/icons/Compare_Icons.svg" class="menu-icon">
        </va-badge>
        <span class="menu-text" :class="getActiveClass('cart')">Comparer</span>
      </div>
    </template>

    <va-dropdown-content class="remove-padding-dropdown cart-dropdown">
      <div v-if="nbItems === 0" style="text-align: center">
        <va-icon name="shopping_cart" size="4rem" color="primary" style="margin-top: 5rem"/>
        <br>
        Votre liste est vide
      </div>
      <div v-else style="padding: 20px">
        <span class="nb-article">{{ nbItems }} produits à comparer</span><br>
        <span class="desc-drop">La quantité pourra être éditée dans la section suivante du comparateur.</span><br>
        <div style="max-height: 320px; overflow: auto; ">

          <div v-for="item in currentCart" :key="item.id" style="height: 115px">
            <div style="display: flex" class="m-2">

              <img :src="item.img_url" class="img-product" fit="cover" v-if="item.img_url"/>
              <img src="https://fakeimg.pl/600x400?text=No+image" class="img-product pointer" fit="cover"
                   v-else/>
              <div
                  style="    align-self: center;    align-self: center;text-overflow: ellipsis;    overflow: hidden;white-space: nowrap;">
                {{ item.name }}<br>
                <!--                <va-counter :min="0" width="100px" color="secondary"/>-->
                <!--              <div class="mt-3">-->
                <!--                <va-counter :min="0" v-model="item.quantity" disabled class="mr-5"/>-->
                <!--                <va-button icon="delete" color="primary" disabled class="ml-5"/>-->
                <!--              </div>-->
              </div>
            </div>
            <va-divider/>
          </div>

        </div>
        <div style="text-align-last: center;">
          <va-button color="secondary" class="mt-3 mb-3" @click="OpenCart()">
            Comparer
          </va-button>
        </div>

      </div>
    </va-dropdown-content>
  </va-dropdown>

</template>
<script>
import {auth} from "@/services/auth";
import firebase from "firebase/compat/app";

export default {
  components: {},
  data: () => ({
    currentCart: {},
    nbItems: 0,
    user: null,
  }),
  methods: {

    addBlurBody() {
      if (!this.showDropdown()) return;

      document.getElementById('content-blur').style.filter = "blur(4px)";
    },
    removeBlur() {
      if (!this.showDropdown()) return;

      document.getElementById('content-blur').style.filter = "blur(0px)";
    },
    showDropdown() {
      //   show only if page is not /cart
      return this.$route.name !== 'cart';
    },
    OpenCart() {
      this.removeBlur();

      this.$router.push('/cart');
    },
    getActiveClass(routeName) {
      // check in the url if the current route is the same as the one passed in parameter
      if (this.$route.name === routeName) {
        return 'menu-text-active'
      } else {
        return 'menu-text'
      }
    },
    async getCart() {
      this.currentCart = {};
      this.nbItems = 0;
      const cart = await firebase.firestore()
          .collection('users')
          .doc(this.user.uid)
          .collection('cart')
          .get();

      cart.forEach((doc) => {
        firebase.firestore()
            .collection('products')
            .doc(doc.id).get().then((product) => {
          if (!product.exists) {
            return;
          }
          this.currentCart[doc.id] = product.data();
          this.currentCart[doc.id]['quantity'] = doc.data().quantity;
          this.nbItems += 1;
        });
      });
    },
  },
  async mounted() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.user = user;
        firebase.firestore()
            .collection('users')
            .doc(this.user.uid)
            .collection('cart').onSnapshot(() => {
          this.getCart();
        });
      }
    });
  },

};
</script>
<style>
.remove-padding-dropdown {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.cart-dropdown {
  min-width: 200px;
  min-height: 300px;
  max-width: 550px;
  max-height: 650px !important;
  width: auto;
  padding-top: 50px;
  padding-bottom: 5px;
  padding-left: 10px;
  border-radius: 0px 0px 30px 30px !important;
  margin-top: 45px !important;
}

.menu-text {
  color: var(--gris-clair-c-9-c-9-c-9, #C9C9C9);
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.nb-article {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.desc-drop {
  color: var(--gris-fonc-858585, #858585);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
}
</style>

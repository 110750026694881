<template>
  <VaModal
      class="modal-crud"
      :model-value="showModal"
      title="Ajouter un produit au stock"
      size="medium"
      @ok="addToStock"
      @cancel="resetEditedItem"
      id="modal-edit-product"
      close-button
      hide-default-actions
      blur
  >
    <va-input
        id="modal-crud"
        v-for="field in modalFields"
        :key="field.key"
        :label="field.label"
        :type="field.type"
        :disabled="!item.isCustomProduct && field.key !== 'category' && field.key !== 'unitPrice'"
        v-model="editedItem[field.key]"
        class="mb-4"
    />
    <va-counter
        v-for="field in modalCounter"
        :key="field.key"
        :label="field.label"
        :min="0"
        manual-input
        v-model="editedItem[field.key]"
        class="mb-4 w-100"
    />
    <template #footer="{ ok, cancel }">
      <VaCardActions>
        <VaButton @click="ok" size="small" round color="primary">
          Ajouter au stock
        </VaButton>
        <VaButton @click="cancel" size="small" round color="backgroundPrimary">
          Annuler
        </VaButton>
        <va-spacer/>
        <VaButton @click="removeFromStock" size="small" round color="danger" v-if="editedItem.step !== 'create'">
          Supprimer du stock
        </VaButton>
      </VaCardActions>
    </template>
  </VaModal>
</template>

<script>
import firebase from "firebase/compat/app";
import {auth} from "@/services/auth";

export default {
  props: ['item'],
  data() {
    return {
      editedItem: {},
      itemId: null,
      showModal: false,
      modalFields: [
        {key: "name", label: "Produit", type: "text", isDisabled: false},
        {key: "ref_brand", label: "Reference fabricant", type: "text", isDisabled: false},
        {key: "brand", label: "Marque", type: "text", isDisabled: false},
        {key: "category", label: "Catégorie", type: "text", isDisabled: false},
        {key: "unitPrice", label: "Prix unitaire (€)", type: "number", isDisabled: false},
      ],
      modalCounter: [
        {key: "stock", label: "Stock actuel", type: "number", isDisabled: false},
        {key: "alertStock", label: "Seuil d'alerte", type: "number", isDisabled: false},
      ],
      newBookmarkName: '',
    };
  },
  methods: {
    searchItemInStock() {
      firebase.firestore()
          .collection('users')
          .doc(auth.currentUser.uid)
          .collection('stock')
          .doc(this.editedItem.id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              this.editedItem = doc.data();
              this.editedItem.id = doc.id;
              this.editedItem.step = 'update';
              this.showModal = true;
            } else {
              this.editedItem = this.item;
              this.showModal = true;
            }
          });
    },

    resetEditedItem() {
      // this.editedItem = null;
      this.showModal = false;
    },
    addToStock() {
      this.editedItem.action = "Ajouté";
      this.editedItem.value = this.editedItem.unitPrice * this.editedItem.stock;
      // convert to int
      this.editedItem.stock = parseInt(this.editedItem.stock);
      this.editedItem.alertStock = parseInt(this.editedItem.alertStock);

      if (this.editedItem.isCustomProduct && this.editedItem.id == null) {
        this.editedItem.id = 'custom-' + this.editedItem.name.replaceAll(/\s/g, '') + '-' + this.editedItem.brand.replaceAll(/\s/g, '') + '-' + this.editedItem.ref_brand.replaceAll(/\s/g, '');
      }

      firebase.firestore()
          .collection('users')
          .doc(auth.currentUser.uid)
          .collection('stock')
          .doc(this.editedItem.id)
          .set(this.editedItem)
          .then(() => {
            this.showModal = false;
            this.$vaToast.init({message: 'Produit ajouté au stock', position: 'bottom-right'})
          });
    },
    removeFromStock() {
      this.$vaModal.init({
        title: 'Voulez-vous vraiment supprimer ce produit du stock ?', // title
        message: 'Cette action est irréversible', // message
        blur: true,
        okText: 'CONFIRMER',
        cancelText: 'ANNULER',
        onOk: () => {
          //  remove from stock
          firebase.firestore()
              .collection('users')
              .doc(auth.currentUser.uid)
              .collection('stock')
              .doc(this.editedItem.id)
              .delete()
              .then(() => {
                this.showModal = false;
                this.$vaToast.init({message: 'Produit supprimé du stock', position: 'bottom-right', color: 'danger'})
              });
        },
        onCancel: () => console.log('clicked CANCEL'),
      })
    },
  },
};
</script>

<style>
.fav-name {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fav-desc {
  color: var(--gris-fonc-858585, #858585);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}


.btn-create-fav {
  display: flex;
  width: 246px;
  height: 40px;
  padding: 20px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--blanc-ffffff, #FFF) !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
  border-radius: 60px !important;
  background: var(--orange-ffab-59, #FFAB59) !important;
}

.custom-h3 {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-family: Montserrat;
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.42px;
}

.table-fav {
  background: #fff;
  top: 0 !important;
}

.va-modal__dialog {
  border-radius: 30px !important;
}

</style>
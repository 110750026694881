
import {defineComponent} from 'vue'
import RegisterComponent from '@/components/RegisterComponent.vue'
import LoginComponent from '@/components/LoginComponent.vue'
import {useRouter} from "vue-router";


export default defineComponent({
  components: {
    RegisterComponent,
    LoginComponent
  },
  setup() {
    const router = useRouter(); // get a reference to our vue router

    return {
      router,
    };
  },
  data() {
    return {
      renderComponent: true,
      isDarkTheme: localStorage.getItem('theme') != "light",
    }
  },
  methods: {
    handleLoggedIn(user) {
      // Handle the logged in user here
      console.log('User has logged in: ', user);
      this.router.push('/');
    },
    changeMode() {
      this.renderComponent = !this.renderComponent;
    }
  }
})

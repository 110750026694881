<template>
  <div class="default-border-bottom title-section pt-4" style="z-index: 2;   background: #f8f7fd;">
    <span class="ml-5 section-title">Administration de la plateforme</span>
  </div>
  <div class="default-border-bottom title-section pt-4" style="z-index: 2;   background: #f8f7fd;">
    <span class="ml-5 section-title">Gestion des utilisateurs</span>
  </div>
  <div class="p-1 default-border-bottom" style="background: white">
    <div class="grid sm:grid-cols-2 md:grid-cols-5 gap-6 mb-6 mt-4">

      <VaInput
          v-model="userTable.filter"
          class="sm:col-span-2 md:col-span-3"
          placeholder="Rechercher un utilisateur..."
      />
      <VaDataTable
          class="table-crud"
          :items="userTable.items"
          :columns="userTable.columns"
          :per-page="userTable.perPage"
          :current-page="userTable.currentPage"
          :filter="userTable.filter"
          :loading="userTable.loading"
          @filtered="userTable.filtered = $event.items"
          striped
      >

        <template #headerAppend>
          <tr class="table-crud__slot">
            <th
                v-for="key in Object.keys(userTable.createdItem)"
                :key="key"
                class="p-1"
            >
              <VaInput
                  v-model="userTable.createdItem[key]"
                  :placeholder="key"
              />
            </th>
            <th class="p-1">
              <VaButton
                  :disabled="userTable.createdItem.email === ''"
                  block
                  @click="addUser"
              >
                Ajouter
              </VaButton>
            </th>
          </tr>
        </template>
        <template #bodyAppend>
          <tr>
            <td colspan="6">
              <div class="flex justify-center mt-4">
                <VaPagination
                    v-model="userTable.currentPage"
                    :pages="pagesUsers"
                    color="secondary"
                />
              </div>
            </td>
          </tr>
        </template>
        <template #cell(actions)="{ rowIndex }">
          <VaButton
              preset="plain"
              icon="delete"
              class="ml-3"
              @click="deleteUser(rowIndex)"
          />
        </template>
      </VaDataTable>
    </div>
  </div>

  <div class="default-border-bottom title-section pt-4" style="z-index: 2;   background: #f8f7fd;">
    <span class="ml-5 section-title">Gestion du matching</span>
  </div>
  <div class="p-3 default-border-bottom" style="background: white">
    <div class="grid sm:grid-cols-2 md:grid-cols-5 gap-6 mb-6 mt-4">

      <VaInput
          v-model="filter"
          class="sm:col-span-2 md:col-span-3"
          placeholder="Filtrer..."
      />
      <VaDataTable
          class="table-crud"
          :items="matchings"
          :columns="columns"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="filter"
          :loading="loadingMatching"
          @filtered="filtered = $event.items"
          striped
      >

        <template #headerAppend>
          <tr class="table-crud__slot">
            <th
                v-for="key in Object.keys(createdItem)"
                :key="key"
                class="p-1"
            >
              <VaInput
                  v-model="createdItem[key]"
                  :placeholder="key"
              />
            </th>
            <th class="p-1">
              <VaButton
                  :disabled="!isNewData"
                  block
                  @click="addMatching"
              >
                Ajouter
              </VaButton>
            </th>
          </tr>
        </template>
        <template #bodyAppend>
          <tr>
            <td colspan="6">
              <div class="flex justify-center mt-4">
                <VaPagination
                    v-model="currentPage"
                    :pages="pages"
                    color="secondary"
                />
              </div>
            </td>
          </tr>
        </template>
        <template #cell(actions)="{ rowIndex }">
          <VaButton
              preset="plain"
              icon="delete"
              class="ml-3"
              @click="deleteMatching(rowIndex)"
          />
        </template>
      </VaDataTable>
    </div>
  </div>
  <div class="default-border-bottom p-3" style="background: white">
    <div v-if="user" class="row justify-space-evenly">
      <div class="flex flex-col xs3 content-profile">
        <div class="item">
          <span class="profile-title mb-3">URL du produit</span>
          <va-input class="mt-3 w-100" v-model="product_url" placeholder="URL"></va-input>
        </div>
      </div>
      <div class="flex flex-col xs3 content-profile">
        <div class="item">
          <span class="profile-title">Site</span><br>
          <va-select v-if="availableSites" v-model="site" placeholder="Choisir site"
                     :options="availableSites.map((site) => site.name)"
                     class="mt-3"></va-select>
        </div>
      </div>
      <div class="flex flex-col xs2 content-profile">
        <div class="item">
          <va-button type="button" class="mt-4" disabled>
            Ajouter produit
          </va-button>
        </div>
      </div>
    </div>
  </div>
  <div class="default-border-bottom title-section pt-4" style="z-index: 2;   background: #f8f7fd;">
    <span class="ml-5 section-title">Historique des chargements</span>
  </div>
  <div class="default-border-bottom title-section p-4" style="z-index: 2;   background: #f8f7fd;">
    <va-button @click="launchScrapping()">Recharger les données</va-button>
  </div>
  <table class="va-table w-100" style="z-index: 0">
    <colgroup>
      <col span="1" style="width: 16%;">
      <col span="1" style="width: 16%;">
      <col span="1" style="width: 14%;">
      <col span="1" style="width: 14%;">
      <col span="1" style="width: 14%;">
      <col span="1" style="width: 14%;">
      <col span="1" style="width: 14%;">
    </colgroup>
    <thead class="table-header">
    <tr>
      <th class="round-header-left">Date</th>
      <th>Statut</th>
      <th>Produits ajoutés</th>
      <th>Produits à ajouter</th>
      <th>Produits dans Firestore</th>
      <th>Produits dans BigQuery</th>
      <th class="round-header-right">Produits à supprimer</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="ingest in ingestion" :key="ingest">
      <td>{{ ingest.timestamp.toDate().toLocaleString() }}</td>
      <td>
        <va-badge v-if="ingest.status === 'running'" color="primary" text="En cours"/>
        <va-badge v-else-if="ingest.status === 'done'" color="success" text="Terminé"/>
        <va-badge v-else-if="ingest.status === 'failed'" color="error" text="Echoué"/>
        <va-badge v-else-if="ingest.status === 'canceled'" color="warning" text="Annulé"/>
      </td>
      <td>{{ ingest.total_products_inserted }}</td>
      <td>{{ ingest.total_products_to_insert }}</td>
      <td>{{ ingest.total_products_in_firestore }}</td>
      <td>{{ ingest.total_products_in_table }}</td>
      <td>{{ ingest.total_products_to_delete }}</td>
    </tr>
    </tbody>
  </table>
</template>
<script>
import {auth} from "@/services/auth";
import firebase from "firebase/compat/app";
import axios from "axios";

const defaultItem = {
  brand: "",
  brand_final: "",
};
export default {
  components: {},
  data() {
    let matchings = [];
    let items = [];
    return {
      user: auth.currentUser,
      userInfo: null,
      availableSites: [],
      product_detail: {},
      brand: '',
      brand_final: '',
      product_url: '',
      site: '',
      ingestion: [],
      items: [],
      loadingMatching: true,
      matchings: matchings,
      columns: [
        {key: "brand", sortable: true},
        {key: "brand_final", sortable: true},
        {key: "inserted_date", sortable: true},
        {key: "actions", width: 80},
      ],
      perPage: 10,
      currentPage: 1,
      filter: "",
      filtered: matchings,
      editedItemId: null,
      editedItem: null,
      createdItem: {...defaultItem},
      userTable: {
        items: items,
        loading: true,
        filter: "",
        filtered: items,
        perPage: 10,
        currentPage: 1,
        createdItem: {email: ''},
        columns: [
          {key: "email", sortable: true},
          {key: "actions", width: 80},
        ],
      }
    }
  },
  computed: {
    pagesUsers() {
      return this.userTable.perPage && this.userTable.perPage !== 0
          ? Math.ceil(this.userTable.filtered.length / this.userTable.perPage)
          : this.userTable.filtered.length;
    }, pages() {
      return this.perPage && this.perPage !== 0
          ? Math.ceil(this.filtered.length / this.perPage)
          : this.filtered.length;
    }, isNewData() {
      return Object.keys(this.createdItem).every(
          (key) => !!this.createdItem[key]
      );
    }
  },
  methods: {
    launchScrapping() {
      console.log('launch scrapping');
      // check ingestion status before launching a new one (only one ingestion at a time)
      firebase.firestore()
          .collection('ingestion')
          .orderBy('timestamp', 'desc')
          .limit(1)
          .get()
          .then((querySnapshot) => {
            console.log(querySnapshot);
            if (querySnapshot.empty) {
              //   call backend to launch process
              axios.post(`${process.env.VUE_APP_BACKEND_URL}/launch-scrapping`).then((response) => {
                console.log(response);

              }).catch((error) => {
                console.log(error);
              });
              return;
            }
            querySnapshot.forEach((doc) => {
              if (doc.data()['status'] === 'running') {
                alert('Une ingestion est déjà en cours, veuillez patienter.');
              } else {
                //   call backend to launch process
                axios.post(`${process.env.VUE_APP_BACKEND_URL}/launch-scrapping`).then((response) => {
                  console.log(response);

                }).catch((error) => {
                  console.log(error);
                });
              }
            });
          }).catch((error) => {
        console.log(error);

      });

    },
    async getAvailableSites() {
      const sites = await firebase.firestore()
          .collection('configuration')
          .doc('sites')
          .get();

      this.availableSites = [];
      sites.data()['availableSites'].forEach((doc) => {
        this.availableSites.push({'name': doc, 'checked': this.userInfo['sites'].includes(doc)});
      });
    },
    async getUsers() {
      this.userTable.loading = true;

      axios.get(`${process.env.VUE_APP_BACKEND_URL}/users`).then((response) => {
        console.log(response);
        this.userTable.items = response.data;
        this.userTable.loading = false;

      }).catch((error) => {
        console.log(error);
      });
    },
    async addUser() {
      this.userTable.loading = true;
      console.log(this.userTable.createdItem);
      axios.post(`${process.env.VUE_APP_BACKEND_URL}/users`, {
        'email': this.userTable.createdItem.email
      }).then(() => {
        this.userTable.loading = false;
        this.userTable.items.push(this.userTable.createdItem);
        this.userTable.createdItem = {email: ''};
      }).catch((error) => {
        console.log(error);
      });

    },
    async deleteUser(rowIndex) {
      this.$vaModal.init({
        title: 'Voulez-vous vraiment supprimer cet utilisateur ?', // title
        message: 'Cette action est irréversible', // message
        blur: true,
        okText: 'CONFIRMER',
        cancelText: 'ANNULER',
        onOk: () => {

          axios.delete(`${process.env.VUE_APP_BACKEND_URL}/users?email=${this.userTable.items[rowIndex].email}`).then((response) => {
            console.log(response);

            this.userTable.items.splice(rowIndex, 1);
          }).catch((error) => {
            console.log(error);
          });
        },
        onCancel: () => console.log('clicked CANCEL'),
      })
    },
    async getMatchingBrands() {
      this.loadingMatching = true;

      axios.get(`${process.env.VUE_APP_BACKEND_URL}/matching`,).then((response) => {
        console.log(response);
        this.matchings = response.data;
        //  order by timestamp and convert to date format for display
        this.matchings.sort((a, b) => {
          return b.inserted_date - a.inserted_date;
        });
        this.matchings.forEach((matching) => {
          matching.inserted_date = new Date(matching.inserted_date).toLocaleString();
          //   truncate hours
          matching.inserted_date = matching.inserted_date.substring(0, matching.inserted_date.length - 9);
        });
        this.loadingMatching = false;

      }).catch((error) => {
        console.log(error);
      });
    },
    async addMatching() {
      this.loadingMatching = true;

      axios.post(`${process.env.VUE_APP_BACKEND_URL}/matching`, {
        'brand': this.createdItem.brand,
        'brand_final': this.createdItem.brand_final
      }).then(() => {
        this.loadingMatching = false;

        this.matchings.push(this.createdItem);
        this.createdItem = {...defaultItem};
      }).catch((error) => {
        console.log(error);
      });

    },
    async deleteMatching(rowIndex) {
      this.loadingMatching = true;

      axios.delete(`${process.env.VUE_APP_BACKEND_URL}/matching?brand=${this.matchings[rowIndex].brand}&brand_final=${this.matchings[rowIndex].brand_final}`).then((response) => {
        console.log(response);
        //   remove from table
        this.loadingMatching = false;

        this.matchings.splice(rowIndex, 1);
      }).catch((error) => {
        console.log(error);
      });

    }
  },

  async mounted() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.getUsers();
        this.getMatchingBrands();
        this.user = user;
        this.userInfo = (await firebase.firestore()
            .collection('users')
            .doc(this.user.uid)
            .get()).data();
        await this.getAvailableSites();

        // get all documents from collection 'ingestion' and listen for changes
        firebase.firestore()
            .collection('ingestion')
            .onSnapshot((querySnapshot) => {
              this.ingestion = [];
              querySnapshot.forEach((doc) => {
                this.ingestion.push(doc.data());
              });
              //   order by timestamp
              this.ingestion.sort((a, b) => {
                return b.timestamp - a.timestamp;
              });
            });
      } else {
        this.user = null;
      }
    });
  }
};
</script>
<style>

.content-profile {
  align-self: center;
}

.title-profile {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
}


.profile-title {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#cancel .va-button__content {
  color: var(--mauve-6246-dc, #6246DC) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: 0.48px !important;
}

.va-data-table .va-data-table__table .va-data-table__table-thead th {
  top: 0px !important;
}
</style>

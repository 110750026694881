<template>
  <va-navbar id="header" :fixed="true"
             color="backgroundSecondary" style="z-index: 10; height: 78px;   border-bottom: 1px solid rgb(0 0 0 / 10%)">
    <template #left>
      <va-navbar-item class="pointer ml-5" @click="redirect('search')">
        <img src="../assets/img/logo_alternate.png" class="mx-auto d-block" height="37"/>
      </va-navbar-item>
    </template>
    <!-- logout-->
    <template #right>
      <UserProfileNavbar class="mr-5"/>
    </template>
    <template #center>
      <div class="nav">
        <VaButton @click="redirect('search')" round icon="search" :class="getActiveClass('search')"
                  :color="getActiveColor('search')" :icon-color="getActiveButtonColor('search')">
          Rechercher
        </VaButton>

        <VaButton :class="getActiveClass('stock')" @click="redirect('stock')" round icon="inventory"
                  :color="getActiveColor('stock')" :icon-color="getActiveButtonColor('stock')">
          Stock
        </VaButton>

        <div class="menu-icon menu-item" v-if="false">
          <HoverCartComponent/>
        </div>

        <VaButton :class="getActiveClass('cart')" @click="redirect('cart')" round icon="compare_arrows"
                  :color="getActiveColor('cart')" :icon-color="getActiveButtonColor('cart')">
          Comparer
          <va-badge
              overlap
              class="ml-1"
              offset="0px"
              style="--va-badge-text-wrapper-border-radius: 50%;"
              :text="nbItems"
              placement="right"
              textColor="#FFFFFF"
              color="secondary">
          </va-badge>
        </VaButton>

        <VaButton :class="getActiveClass('bookmarks')" @click="redirect('bookmarks')" round icon="star_border"
                  :color="getActiveColor('bookmarks')" :icon-color="getActiveButtonColor('bookmarks')">
          Favoris
        </VaButton>

        <VaButton :class="getActiveClass('profile')" @click="redirect('profile')" round icon="person_outline"
                  :color="getActiveColor('profile')" :icon-color="getActiveButtonColor('profile')">
          Compte
        </VaButton>

        <VaButton :class="getActiveClass('admin')" @click="redirect('admin')" round icon="settings"
                  :color="getActiveColor('admin')" :icon-color="getActiveButtonColor('admin')"
                  v-if="userInfo && userInfo['is_admin_account']">
          Admin
        </VaButton>
      </div>
    </template>
  </va-navbar>
  <div style="height:78px; width:100%; clear:both;"></div>

  <div id="content-blur">
    <router-view name="home"></router-view>
  </div>
  <!--  <div style="height:40px; width:100%; clear:both;"></div>-->

</template>

<script>
import {defineComponent} from 'vue';
import firebase from 'firebase/compat/app';
import {useRouter} from 'vue-router';
import UserProfileNavbar from "@/components/UserProfileNavbar.vue";
import HoverCartComponent from "@/components/HoverCartComponent.vue";
import {auth} from "@/services/auth";

export default defineComponent({
  components: {HoverCartComponent, UserProfileNavbar},

  data() {
    return {
      renderingPage: 'dashboard',
      userInfo: null,
      nbItems: 0,
      user: null,
      currentCart: {}
    }
  },
  methods: {
    redirect(route) {
      this.$router.push('/' + route)
    },
    getActiveClass(routeName) {
      // check in the url if the current route is the same as the one passed in parameter
      if (this.$route.name === routeName) {
        return 'menu-text-active'
      } else {
        return 'menu-text'
      }
    }, getActiveColor(routeName) {
      // check in the url if the current route is the same as the one passed in parameter
      if (this.$route.name === routeName) {
        return 'primary'
      } else {
        return 'backgroundSecondary'
      }
    }, getActiveButtonColor(routeName) {
      // check in the url if the current route is the same as the one passed in parameter
      if (this.$route.name === routeName) {
        return '#fff'
      } else {
        return '#000'
      }
    }, async getCart() {
      this.currentCart = {};
      this.nbItems = 0;
      const cart = await firebase.firestore()
          .collection('users')
          .doc(this.user.uid)
          .collection('cart')
          .get();

      cart.forEach((doc) => {
        firebase.firestore()
            .collection('products')
            .doc(doc.id).get().then((product) => {
          if (!product.exists) {
            return;
          }
          this.currentCart[doc.id] = product.data();
          this.currentCart[doc.id]['quantity'] = doc.data().quantity;
          this.nbItems += 1;
        });
      });
    },
  },
  async mounted() {
    const router = useRouter()
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.user = user
        this.userInfo = (await firebase.firestore()
            .collection('users')
            .doc(user.uid)
            .get()).data();
        firebase.firestore()
            .collection('users')
            .doc(this.user.uid)
            .collection('cart').onSnapshot(() => {
          this.getCart();
        });

      } else {
        router.push('/login')
      }
    });
  },
  setup() {
    const router = useRouter()
    firebase.auth().onAuthStateChanged(async function (user) {
          if (!user) { // not logged in
            // alert('you must be logged in to view this. redirecting to the home page')
            router.push('/login')
          } else {
            if (!user.emailVerified) {
              router.push('/confirm-email')
              return
            }
            const docSnap = await firebase.firestore()
                .collection('users')
                .doc(user.uid).get();
            if (docSnap.exists) {
              const data = docSnap.data();
              if (data && 'is_verified' in data) {
                const isVerified = data['is_verified'];
                // const isValidated = data['is_validated'];
                // console.log(isVerified, isValidated)
                if (!isVerified) {
                  router.push('/confirm-account')
                  return
                }

                const docAuth = await firebase.firestore()
                    .collection('authorized_users')
                    .doc(user.email).get();
                if (!docAuth.exists) {
                  router.push('/wait-validation')
                  return
                }
              } else {
                console.log('is_verified or is_validated not in data')
                console.log(data)
              }
            } else {
              console.log('docSnap does not exist')
            }

            if (router.currentRoute.value.path === '/') {
              router.push('/search')
            }
          }
        }
    )
  },
})
</script>
<style>
.nav {
  display: inline-flex;
  align-items: flex-start;
  gap: 15px;
}

.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 11px;
}

.menu-icon {
  width: 30px;
  height: 30px;
}

.menu-text {
  color: var(--Black, #000) !important;
  text-align: center !important;
  leading-trim: both !important;
  text-edge: cap !important;
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 110%; /* 17.6px */
}

.menu-text-active {
  color: var(--noir-0-e-0-e-0-e, #ffffff) !important;
  text-align: center !important;
  leading-trim: both !important;
  text-edge: cap !important;
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 110%; /* 17.6px */
}


</style>

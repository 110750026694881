<template>
  <div class="default-border-bottom fix-action-button pt-3" style="z-index: 2;   background: #f8f7fd;">
    <va-button v-if="nbItems>0" icon="checklist" @click="autoSelectProducts()" id="btn-auto" round class="ml-5"
               size="medium"
    >Sélection automatique
    </va-button>
    <va-button v-if="nbItems>0" icon="rule" icon-color="backgroundSecondary" @click="unselectProducts()" id="btn-auto"
               class="ml-3" size="medium"
               color="secondary" round>Tout déselectionner
    </va-button>
    <va-button v-if="nbItems>0" icon="delete" border-color="primary" preset="secondary" class="ml-3" size="medium"
               @click="emptyCart()"
               id="remove-btn" round>Vider le comparateur
    </va-button>
  </div>
  <div>
    <table class="va-table w-100" v-if="nbItems>0">
      <colgroup>
        <col span="1" style="width: 25%;">
        <th v-for="site in availableSites" :key="site">
          <col span="1" :style="'width: '+ 60 / availableSites.length +'%;'">
        </th>
        <col span="1" style="width: 15%;">
      </colgroup>

      <thead class="table-header compare-table-header" v-if="this.user">
      <tr>
        <th class="pl-5">Produit</th>
        <th>Marque</th>
        <th v-for="site in availableSites" :key="site">
          <va-checkbox v-model="checkboxSelectAllproducts[site]" @click="selectAllProductForSite(site)"
                       :label="site.toUpperCase()"></va-checkbox>
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in currentCart" :key="item.objectID" class="border-bottom">
        <td class="cell-product">
          <!--          <va-icon color="primary" class="mr-4 pointer" :name="'favorite_border'"></va-icon>-->
          <va-icon @click="clickOnStar(item.objectID)"
                   :name="isStarred(item.objectID)" color="primary" class="mr-4 pointer"></va-icon>
          <img :src="item.img_url" @click="showProduct(item)" class="img-product-cart" v-if="item.img_url"/>
          <img src="../assets/icons/no_image.svg" class="img-product-cart" v-else/>

          <span class="ml-3 product-title">{{ item.name }}<br><span
              class="product-description">Réf. fab: {{ item.ref_brand }}</span></span>

        </td>

        <td class="brand">
          {{ item.brand }}
        </td>
        <template v-if="productIsAvailable(item.objectID)">
          <td v-for="site in availableSites" :key="site" class="th-left">
            <va-chip size="small" v-if="isBestPrice(site, item.objectID)" style="font-size: 9px; color: white"
                     color="secondary">
              <img src="../assets/icons/Trophy_Icons.svg" class="trophy-icon">Meilleur prix
            </va-chip>
            <va-chip size="small" v-if="isOutOfStock(site, item.objectID)" style="font-size: 9px; color: white"
                     color="danger">
              <va-icon name="close" class="trophy-icon" size="14px"/>
              Stock épuisé
            </va-chip>
            <div v-if="!loadingSiteProducts[item.objectID][site]">
              <va-progress-bar indeterminate style="width: 50px"/>
            </div>
            <div style="display: flex;align-items: center;"
                 :class="(isBestPrice(site, item.objectID)|| isOutOfStock(site, item.objectID)) ? 'mt-2':'mt-4'"
                 v-else>
              <va-checkbox
                  v-model="checkboxSiteProducts[item.objectID][site]"
                  :disabled="isOutOfStock(site, item.objectID)"
                  v-if="pricesSiteProducts[item.objectID][site]"></va-checkbox>
              <va-popover v-else message="Article non disponible" placement="top">
                <img src="../assets/icons/No_price.svg">
              </va-popover>
              <span class="ml-3 pointer price-cart" @click="openLink(site, item.objectID)"
                    v-if="pricesSiteProducts[item.objectID][site]">{{
                  pricesSiteProducts[item.objectID][site].toFixed(2) + '€/u'
                }}</span>
            </div>
          </td>
        </template>
        <td v-else :colspan="availableSites.length" style="text-align: center">
          <va-chip size="large" style="font-size: 13px; color: white"
                   color="primary" disabled>
            Produit indisponible
          </va-chip>
        </td>

        <td class="th-left cell-product">
          <va-counter :min="1" v-model="productsWaitUpdate[item.objectID]" width="90px" manual-input
                      @update:modelValue="updateCart(item.objectID)" color="secondary"/>
          <img class="pointer ml-2" @click="removeFromCart(item.objectID)" src="../assets/icons/Trash_Icons.svg"/>
        </td>
      </tr>
      </tbody>
      <tfoot style="background: white" class="default-border-bottom">
      <!-- Frais de port -->
      <tr>
        <td style="padding-left: 80px">Frais de port</td>
        <td>
        </td>
        <td v-for="site in availableSites" :key="site">
          {{ calculateShipping(site).toFixed(2) }}€
        </td>

        <td></td>
      </tr>
      <!-- Total -->
      <tr>
        <td style="padding-left: 80px;">Total</td>
        <td>
        </td>
        <td v-for="site in availableSites" :key="site" >
          {{ (Math.round((getTotalCartBySite(site)[0] + calculateShipping(site)) * 100) / 100).toFixed(2) }}€ <p
            v-if="getTotalCartBySite(site)[1]>0">({{ getTotalCartBySite(site)[1].toFixed(0) }} article<span
            v-if="getTotalCartBySite(site)[1]>1">s</span>)</p>
        </td>


        <td class="price-cart total-price">
          {{ getAllSitesTotal().toFixed(2) }} €
        </td>
      </tr>
      </tfoot>
    </table>
  </div>
  <div v-if="nbItems === 0" style="text-align: center">
    <va-icon name="shopping_cart" size="4rem" color="primary" class="mt-5"/>
    <br>
    Votre liste est vide
  </div>

  <div v-if="nbItems>0">
    <div class="row m-5">
      <div class="flex flex-col md7">
        <div class="setting-title ml-5">
          <img src="../assets/icons/Discount_Icons.svg" class="menu-icon">
          <p class="congrats-text">Félicitations, vous pouvez économiser <span
              class="saving-text">{{ calculateEstimatedGainOnSelected()[0].toFixed(2) }}€<va-popover
              message="Hors frais de port" placement="top">*</va-popover></span></p>
        </div>
        <span class="congrats-sub" v-if="user.data['favorite_site']">Économie calculée sur la base du site préféré: {{
            user.data['favorite_site'].toUpperCase()
          }}</span>
      </div>
      <div class="flex flex-col md5">

        <va-button type="button" class="default-btn-style" color="primary" @click="openExtension()" v-if="nbItems>0"
                   round size="large">
          Passez commande
        </va-button>
        <!--        <va-button color="primary" @click="openLinksChecked()" v-if="nbItems>0">-->
        <!--          Ouvrir les liens-->
        <!--        </va-button>-->
      </div>
    </div>
  </div>

  <va-card class="m-5" v-if="nbItems>0">
    <!--  card with shipping rates information-->
    <va-card-title class="info-title"><img src="../assets/icons/Info_Icons.svg">Informations sur les frais de port
    </va-card-title>
    <va-card-content class="info-content">
      <p>GACD: En-dessous de 150€ d'achat sur votre commande, la préparation et la livraison de votre commande vous
        seront facturées 10 € seulement.</p>
      <p>Doctor Strong : A partir de 130 € d’achat, la livraison est OFFERTE par Doctor Strong. Si votre commande
        est inférieure à 130 €, les frais de port sont à 7,40 €.</p>
      <p>Dentaltix : A partir de 200 € d’achat, la livraison est OFFERTE par Doctor Strong. Si votre commande
        est inférieure à 200 €, les frais de port sont à 15 €.</p>
      <p>MegaDental : A partir de 149 € d’achat, la livraison est OFFERTE par Doctor Strong. Si votre commande
        est inférieure à 149 €, les frais de port sont à 9.95 €.</p>
      <p>Dentalclick : A partir de 200 € d’achat, la livraison est OFFERTE par Doctor Strong. Si votre commande
        est inférieure à 200 €, les frais de port sont à 9 €.</p>
    </va-card-content>

    <!--  card with shipping rates information-->
    <va-card-title class="info-title"><img src="../assets/icons/Info_Icons.svg">Informations sur les délais de livraison
    </va-card-title>
    <va-card-content class="info-content">
      <p>GACD: Livraison sous 48H</p>
      <p>Doctor Strong : Livraison express sous 24H</p>
      <p>Dentaltix : Livraison sous 4 à 6 jours</p>
      <p>MegaDental : Les commandes passées avant 16h sont livrées le lendemain avant 13h00</p>
      <p>Dentalclick : Toute commande passée un jour ouvrable avant 16h00 sera livrée dans un délai de 48 heures.
        Toute commande passée après 16h00 sera expédiée le jour ouvrable suivant</p>
    </va-card-content>
  </va-card>

  <div style="height: 30px"></div>

  <va-modal v-model="showModalPopupWarning" hide-default-actions blur close-button ok-text="ok"
            style="text-align: center">
    <h5 class="va-h5">
      Veuillez autoriser les popups pour ouvrir les liens
    </h5>
    <img src="../assets/img/tuto-popup.jpg"/>
    <template #footer>
      <va-button @click="showModalPopupWarning=false">Ok</va-button>
    </template>
  </va-modal>

  <va-modal v-model="showProductModal" blur hide-default-actions>
    <a :href="product_detail.img_url" target="_blank">
      <va-image :src="product_detail.img_url" class="img-detail">
      </va-image>
    </a>
  </va-modal>

  <va-modal v-model="showResumeModal" v-if="nbItems>0 && false" hide-default-actions size="large" no-padding>
    <div class="m-4" v-if="!orderLoading">
      <h1 class="m-1">Récapitulatif de votre commande:</h1>
      <div class="warning-order">
        <span style="font-size: 17px; display:table-cell; vertical-align:middle; font-weight: bold">
        <va-icon name="warning" size="30px" color="primary"/>
        Pensez à vérifier les quantités et les produits sélectionnés avant de passer commande.
          </span>
      </div>
      <!--      display available sites   -->
      <div v-for="site in availableSites" :key="site">
        <div v-if="getTotalCartBySite(site)[0] > 0">


          <div class="site-order">
            <span style="font-size: 20px; display:table-cell; vertical-align:middle;">
              {{ site.toUpperCase() }}
            </span>
            <span style="font-size: 20px; display:table-cell; vertical-align:middle;">
              {{ getTotalCartBySite(site)[0] }}€
            </span>
          </div>
          <table class="va-table w-100">
            <colgroup>
              <col span="1" style="width: 10%;">
              <col span="1" style="width: 60%;">
              <col span="1" style="width: 15%;">
              <col span="1" style="width: 15%;">
            </colgroup>

            <thead v-if="this.user">
            <tr>
              <th style="top: 0" class="round-header-left">Statut</th>
              <th style="top: 0">Produit</th>
              <th style="top: 0">Marque</th>
              <th style="top: 0" class="round-header-right">Quantité</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in getAllCheckedProductBySite(site)" :key="item.objectID" class="border-bottom">
              <td>
                <va-icon :name="orderResume[item][site]['statut']" size="24px"
                         :color="orderResume[item][site]['statut_color']" style="margin-left:15px"/>
              </td>
              <td class="cell-product"><img :src="currentCart[item].img_url" class="img-product-cart pointer"
                                            v-if="currentCart[item].img_url"/>
                <img src="../assets/icons/no_image.svg" class="img-product-cart pointer"
                     v-else/>
                <span class="ml-3 product-title">{{ currentCart[item].name }}<br><span
                    class="product-description">Réf. fab: {{ currentCart[item].ref_brand }}</span></span>
              </td>
              <td>{{ currentCart[item].brand }}</td>
              <td v-if="orderResume[item][site]['statut'] == 'warning'"><span
                  style="text-decoration: line-through 2px; margin-left: 15px">{{
                  orderResume[item][site]['quantity']
                }}</span> /
                {{ orderResume[item][site]['quantity_available'] }}
              </td>
              <td v-else>{{ productsWaitUpdate[item] }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-else>
      <h5 class="m-3">Veuillez ne pas quitter la page durant l'ajout de vos produits aux paniers</h5>

      <va-progress-circle indeterminate size="large" thickness="0.25"></va-progress-circle>
    </div>

  </va-modal>
  <favorite-modal
      ref="favoriteModalRef"
      :product-to-save="productToSave"
      :user-bookmarks="userBookmarks"
      :starred-products="starredProducts"
      @updateUserBookmarks="handleUserBookmarksUpdate"
      @productFavorited="handleProductFavorited"
  />
</template>

<script>
import firebase from "firebase/compat/app";
import {auth} from "@/services/auth";
import axios from "axios";
import FavoriteModal from "@/components/modals/FavoriteModal.vue";

export default {
  components: {FavoriteModal},
  data() {
    return {
      currentCart: {},
      productsWaitUpdate: {},
      products: {},
      nbItems: 0,
      loading: false,
      user: {},
      availableSites: [],
      loadingSiteProducts: {},
      pricesSiteProducts: {},
      checkboxSiteProducts: {},
      checkboxSelectAllproducts: {},
      totalCartBySite: {},
      orderResume: {},
      showModalPopupWarning: false,
      showProductModal: false,
      showResumeModal: false,
      orderLoading: false,
      totalGain: 0,
      starredProducts: {},
      userBookmarks: [],
      productToSave: '',
    };
  },
  methods: {
    handleUserBookmarksUpdate(newValue) {
      this.userBookmarks.push(newValue);
    }, handleProductFavorited(bookmark) {
      this.starredProducts[this.productToSave] = bookmark;
    },
    showProduct(product) {
      if (product) {
        this.product_detail = product;
        this.showProductModal = true;
      }
    }, clickOnStar(objectID) {
      this.productToSave = objectID;
      if (this.isStarred(objectID) === 'favorite') {
        console.log('click is star', objectID)
        firebase.firestore()
            .collection('users')
            .doc(auth.currentUser.uid)
            .collection('bookmarks')
            .doc(this.starredProducts[objectID])
            .update({product_ids: firebase.firestore.FieldValue.arrayRemove(this.productToSave)});
        this.$vaToast.init({message: 'Produit retiré des favoris', position: 'bottom-right'});
        delete this.starredProducts[objectID];
        return 'favorite_border';
      }
      console.log('click is not star', objectID, this.isStarred(objectID))

      this.$refs.favoriteModalRef.showModal = true;
    },
    isStarred(objectID) {
      if (this.starredProducts[objectID]) {
        return 'favorite';
      }
      return 'favorite_border';
    },
    productIsAvailable(product_id) {
      // function that checks if we have a price for at least one site
      for (let idx in this.availableSites) {
        let site = this.availableSites[idx];
        if (!this.loadingSiteProducts[product_id][site]) {
          return true;
        }
        if (site in this.products[product_id] && this.products[product_id][site]['price'] > 0) {
          return true;
        }
      }
      return false;
    },
    updateCart(product_id) {
      // add product to user cart in firestore
      firebase.firestore()
          .collection('users')
          .doc(auth.currentUser.uid)
          .collection('cart')
          .doc(product_id)
          .set({
            quantity: this.productsWaitUpdate[product_id],
            added_date: new Date(),
          });
      this.currentCart[product_id]['quantity'] = this.productsWaitUpdate[product_id];
    },
    calculateTotalShipping() {
      // function that calculate the total shipping cost
      let total = 0;
      for (let site in this.totalCartBySite) {
        total += this.calculateShipping(site);
      }
      // round to 2 decimals
      total = Math.round(total * 100) / 100;
      return total;
    },
    calculateShipping(site) {
      // function that calculate the shipping cost for a specific site
      if (site === 'gacd') {
        if (this.getTotalCartBySite(site)[0] < 150 && this.getTotalCartBySite(site)[0] > 0) {
          return 10
        }
      } else if (site === 'doctorstrong') {
        if (this.getTotalCartBySite(site)[0] < 130 && this.getTotalCartBySite(site)[0] > 0) {
          return 7.40;
        }
      } else if (site === 'dentalclick') {
        if (this.getTotalCartBySite(site)[0] < 200 && this.getTotalCartBySite(site)[0] > 0) {
          return 9;
        }
      } else if (site === 'dentaltix') {
        if (this.getTotalCartBySite(site)[0] < 200 && this.getTotalCartBySite(site)[0] > 0) {
          return 15;
        }
      } else if (site === 'megadental') {
        if (this.getTotalCartBySite(site)[0] < 149 && this.getTotalCartBySite(site)[0] > 0) {
          return 9.95;
        }
      }
      return 0;
    },
    getSelectedProducts() {
      // function that return the selected products
      let selectedProducts = [];
      for (let product in this.checkboxSiteProducts) {
        for (let site in this.checkboxSiteProducts[product]) {
          if (this.checkboxSiteProducts[product][site]) {
            selectedProducts.push(product);
          }
        }
      }
      //   return unique ids
      return [...new Set(selectedProducts)];
    },
    getAllCheckedProductBySite(site) {
      let selectedProducts = [];
      for (let product in this.checkboxSiteProducts) {
        if (this.checkboxSiteProducts[product][site]) {
          selectedProducts.push(product);
        }
      }
      //   return unique ids
      return [...new Set(selectedProducts)];
    },
    calculateEstimatedGainOnSelected() {
      // function that calculate the estimated gain by comparing the economy made for each product by soustracting the price of the favorite site by the min price found
      // if no favorite site available, use the max price instead
      let total = 0;
      let totalMaxAmout = 0;
      let selectedProducts = this.getSelectedProducts();

      for (let product of selectedProducts) {
        if (this.pricesSiteProducts[product] === undefined) continue;
        let selectedPrice = 0;
        let maxPrice = 0;
        let favPrice = 0;
        let favSite = this.user.data['favorite_site'];
        for (let site in this.pricesSiteProducts[product]) {
          if (this.pricesSiteProducts[product][site] > maxPrice) {
            maxPrice = this.pricesSiteProducts[product][site];
          }
          if (site === favSite) {
            favPrice = this.pricesSiteProducts[product][site];
          }
          if (this.checkboxSiteProducts[product][site]) {
            selectedPrice = this.pricesSiteProducts[product][site];
          }
        }
        if (favPrice !== 0) {
          maxPrice = favPrice;
        }
        totalMaxAmout += maxPrice;
        total += (maxPrice - selectedPrice) * this.productsWaitUpdate[product];
      }

      // round total
      total = Math.round(total * 100) / 100;
      return [total, totalMaxAmout];
    },
    calculateEstimatedGain() {
      // function that calculate the estimated gain by comparing the economy made for each product by soustracting the max price to the min price
      let total = 0;
      for (let product in this.pricesSiteProducts) {
        let minPrice = 1000000;
        let maxPrice = 0;
        for (let site in this.pricesSiteProducts[product]) {
          if (this.pricesSiteProducts[product][site] > maxPrice) {
            maxPrice = this.pricesSiteProducts[product][site];
          }
          if (this.pricesSiteProducts[product][site] < minPrice) {
            minPrice = this.pricesSiteProducts[product][site];
          }
        }
        let gain = maxPrice - minPrice;
        if (gain < 0) {
          gain = 0;
        }
        total += gain * this.productsWaitUpdate[product];
      }
      // round total
      total = Math.round(total * 100) / 100;
      if (total < 0) {
        total = 0;
      }
      this.totalGain = total;
      return total;
    },
    removeFromCart(objectID) {
      // remove product from user cart in firestore
      firebase.firestore()
          .collection('users')
          .doc(auth.currentUser.uid)
          .collection('cart')
          .doc(objectID).delete();
      delete this.productsWaitUpdate[objectID];
      delete this.currentCart[objectID];
      delete this.pricesSiteProducts[objectID];
      delete this.checkboxSiteProducts[objectID];
      this.nbItems -= 1;
      this.calculateEstimatedGain();
    },
    emptyCart() {
      //   remove all products from cart
      for (let product in this.currentCart) {
        firebase.firestore()
            .collection('users')
            .doc(auth.currentUser.uid)
            .collection('cart')
            .doc(product).delete();
        delete this.productsWaitUpdate[product];
      }
      this.nbItems = 0;
      this.currentCart = {};
    },
    unselectProducts() {
      for (let product in this.checkboxSiteProducts) {
        for (let site in this.checkboxSiteProducts[product]) {
          this.checkboxSiteProducts[product][site] = false;
          this.checkboxSelectAllproducts[site] = false;
        }
      }
    },
    autoSelectProducts() {
      // Quand 2 prix sont égaux sur un produit, choisir le prix sur le site qui a le plus de produits selectionnés

      for (let product in this.checkboxSiteProducts) {
        let nbSelected = 0;
        for (let site in this.checkboxSiteProducts[product]) {
          if (this.isBestPrice(site, product)) {
            this.checkboxSiteProducts[product][site] = true;
            nbSelected += 1;
          } else {
            this.checkboxSiteProducts[product][site] = false;
          }
        }

        if (nbSelected > 1) {
          // count nb selected for each site
          let nbSelectedBySite = {};
          for (let site in this.checkboxSiteProducts[product]) {
            if (this.checkboxSiteProducts[product][site]) {
              nbSelectedBySite[site] = 0;
              for (let product2 in this.checkboxSiteProducts) {
                if (this.checkboxSiteProducts[product2][site]) {
                  nbSelectedBySite[site] += 1;
                }
              }
            }
          }
          // get site with max selected
          let maxSelected = 0;
          let maxSelectedSite = '';
          for (let site in nbSelectedBySite) {
            if (nbSelectedBySite[site] > maxSelected) {
              maxSelected = nbSelectedBySite[site];
              maxSelectedSite = site;
            }
          }
          // unselect all other sites
          for (let site in this.checkboxSiteProducts[product]) {
            if (site !== maxSelectedSite) {
              this.checkboxSiteProducts[product][site] = false;
            }
          }
        }

      }
    },
    isBestPrice(site, objectID) {
      if (this.pricesSiteProducts[objectID] === undefined) return false;
      if (this.pricesSiteProducts[objectID][site] === undefined) return false;
      if (this.pricesSiteProducts[objectID][site] === 0) return false;
      let bestPrice = true;

      let price = this.pricesSiteProducts[objectID][site];

      for (const [key, value] of Object.entries(this.pricesSiteProducts[objectID])) {
        if (value === 0) continue;
        if (value < price && this.isOutOfStock(key, objectID) === false) {
          bestPrice = false;
        }
      }
      return bestPrice && this.isOutOfStock(site, objectID) === false;
    },
    isOutOfStock(site, objectID) {
      if (this.products[objectID] === undefined) return false;
      if (this.products[objectID][site] === undefined) return false;
      return this.products[objectID][site]['is_in_stock'] === 0;
    },
    getAllSitesTotal() {
      let total = 0;
      for (const [key, value] of Object.entries(this.totalCartBySite)) {
        total += value;
      }
      total = Math.round(total * 100) / 100;

      return total;
    },
    selectAllProductForSite(site) {
      for (const [key, value] of Object.entries(this.checkboxSiteProducts)) {
        if (this.pricesSiteProducts[key][site] && this.isOutOfStock(site, key) === false)
          this.checkboxSiteProducts[key][site] = this.checkboxSelectAllproducts[site];
      }
    },
    isFavoriteSite(site) {
      return this.user.data['favorite_site'] && this.user.data['favorite_site'] === site;
    },
    async openExtension() {
      this.showResumeModal = true;
      this.orderLoading = true;
      this.orderResume = {};
      let products = [];
      for (const [key, value] of Object.entries(this.currentCart)) {
        this.orderResume[key] = {}
        for (const [key2, value2] of Object.entries(this.checkboxSiteProducts[key])) {
          if (value2) {
            // get link from firestore
            const cart = await firebase.firestore()
                .collection('products')
                .doc(key)
                .collection('sites')
                .doc(key2)
                .get();
            if (cart.data()) {
              let qty = this.productsWaitUpdate[key]
              this.orderResume[key][key2] = {
                'statut': 'check_circle',
                'statut_color': 'primary',
                'quantity': this.productsWaitUpdate[key],
                'quantity_available': this.productsWaitUpdate[key]
              }
              if (['dentaltix', 'gacd'].includes(key2)) {
                let available_quantity = this.products[key][key2]['is_in_stock']
                if (available_quantity < qty) {
                  qty = available_quantity
                  this.orderResume[key][key2] = {
                    'statut': 'warning',
                    'statut_color': 'danger',
                    'quantity': this.productsWaitUpdate[key],
                    'quantity_available': qty
                  }
                }
              }

              products.push({
                'url': cart.data()['url_product_extension'],
                'site': key2,
                'ref': cart.data()['ref_website'],
                'quantity': qty
              });
            }
          }
        }
      }

      // send message to extension
      const event = new CustomEvent('openLinks', {detail: products});
      window.dispatchEvent(event);
      this.saveSelection();
      this.orderLoading = false;
    }
    ,
    async saveSelection() {
      //   Save current cart in user profile with prices
      let order = {
        'products': []
      };
      for (const [product, value] of Object.entries(this.currentCart)) {
        let p = {
          'product_id': product,
          'prices': this.pricesSiteProducts[product],
          'quantity': this.productsWaitUpdate[product],
        }
        for (const [site, value2] of Object.entries(this.checkboxSiteProducts[product])) {
          if (value2) {
            p['site_selected'] = site
          }
        }
        order['products'].push(p);
      }
      order['creation_date'] = new Date();
      order['total'] = this.getAllSitesTotal();
      order['shipping_cost'] = this.calculateTotalShipping();
      order['gain'] = this.calculateEstimatedGainOnSelected()[0];

      await firebase.firestore()
          .collection('users')
          .doc(auth.currentUser.uid).collection('orders').doc().set(order);
    }
    ,
    async openLink(site, product_id) {
      const cart = await firebase.firestore()
          .collection('products')
          .doc(product_id)
          .collection('sites')
          .doc(site)
          .get();
      if (cart.data()) {
        let result = window.open(cart.data()['url_product_extension']);
        if (result === null) {
          this.showModalPopupWarning = true;
        }
      }
    }
    ,
    async openLinksChecked() {
      // open links in new tab
      this.loading = true;
      for (const [key, value] of Object.entries(this.currentCart)) {
        for (const [key2, value2] of Object.entries(this.checkboxSiteProducts[key])) {
          if (value2) {
            // get link from firestore
            const cart = await firebase.firestore()
                .collection('products')
                .doc(key)
                .collection('sites')
                .doc(key2)
                .get();
            if (cart.data()) {
              let result = window.open(cart.data()['url_product_extension']);
              if (result === null) {
                this.showModalPopupWarning = true;
              }
            }
          }
        }
      }
      this.loading = false;
    }
    ,
    countAllProducts() {
      let nbItems = 0;
      for (let product in this.checkboxSiteProducts) {
        for (let site in this.checkboxSiteProducts[product]) {
          if (this.checkboxSiteProducts[product][site])
            nbItems += this.productsWaitUpdate[product];
        }
      }
      // round to 2 decimals
      return nbItems;
    }
    ,
    getTotalCartBySite(site) {
      let total = 0;
      let nbItems = 0;
      for (const [key, value] of Object.entries(this.currentCart)) {
        if (this.checkboxSiteProducts[key][site]) {
          total += this.pricesSiteProducts[key][site] * this.productsWaitUpdate[key];
          nbItems += this.productsWaitUpdate[key];
        }
      }
      this.totalCartBySite[site] = total;
      // round to 2 decimals
      total = Math.round(total * 100) / 100;
      return [total, nbItems];
    }
    ,

    redirectContent(path) {
      this.$router.push(path);
    }
    ,
    async getCart() {
      this.nbItems = 0;
      const cart = await firebase.firestore()
          .collection('users')
          .doc(this.user.uid)
          .collection('cart')
          .get();

      cart.forEach((doc) => {
        firebase.firestore()
            .collection('products')
            .doc(doc.id).get().then((product) => {
          if (!product.exists) return;
          this.currentCart[doc.id] = product.data();
          this.currentCart[doc.id]['quantity'] = doc.data().quantity;
          this.currentCart[doc.id]['objectID'] = doc.id;
          this.nbItems += 1;
          this.productsWaitUpdate[doc.id] = doc.data().quantity;
          this.loadingSiteProducts[doc.id] = {};
          this.pricesSiteProducts[doc.id] = {};
          this.products[doc.id] = {};
          this.checkboxSiteProducts[doc.id] = {};
          this.availableSites.forEach((site) => {
            axios.get(`${process.env.VUE_APP_BACKEND_URL}/get-price?product_id=${doc.id}&site=${site}`,).then((response) => {
              this.products[doc.id][site] = response.data;
              this.pricesSiteProducts[doc.id][site] = Math.round(response.data['price'] * 100) / 100;
              if (site === 'gacd') {
                console.log('gacd')
                // calculate price with promotion of user profile data Apply promotion based on product brand
                if (['RS', 'MEDIBASE', 'DENTOVIRACTIS', 'CLINIX'].includes(this.currentCart[doc.id]['brand_id'])) {
                  // brand is gacd exclusive
                  console.log('brand is gacd exclusive')
                  let price_base = Math.round(response.data['price_base'] * 100) / 100;

                  let discounted_price = Math.round(price_base * (1 - this.user.data['gacd_brands'] / 100) * 100) / 100;
                  if (discounted_price < this.pricesSiteProducts[doc.id][site]) {
                    this.pricesSiteProducts[doc.id][site] = discounted_price;
                  }
                } else {
                  // brand is not exclusive
                  let price_base = Math.round(response.data['price_base'] * 100) / 100;
                  let discounted_price = Math.round(price_base * (1 - this.user.data['gacd_national'] / 100) * 100) / 100;
                  if (discounted_price < this.pricesSiteProducts[doc.id][site]) {
                    this.pricesSiteProducts[doc.id][site] = discounted_price;
                  }
                }
              } else if (site === 'megadental') {
                let excluded_brands = ['Dental excellence',
                  'CYBERTECH',
                  'BA INTERNATIONAL',
                  'MEGAMASTER BY COXO',
                  'ORTHOSMAIL',
                  'FGM',
                  'PROFECTION',
                  'SUPREME ENDO',
                  'A5',
                  'EDGE ENDO',
                  'ARKONA',
                  'FSB INNOVATION',
                  'MEDIFLASH',
                  'PERFECT',
                  'WHITE DENTAL BEAUTY',
                  'ERSKINE',
                  'ACCLEAN']
                if (!excluded_brands.includes(this.currentCart[doc.id]['brand_id'])) {

                  let price = Math.round(response.data['price_base'] * 100) / 100;
                  let discounted_price = Math.round(price * (1 - this.user.data['discount_megadental']) * 100) / 100;
                  if (discounted_price < this.pricesSiteProducts[doc.id][site]) {
                    this.pricesSiteProducts[doc.id][site] = discounted_price;
                  }
                }
              }
              this.loadingSiteProducts[doc.id][site] = true;
              this.checkboxSiteProducts[doc.id][site] = false;
              this.autoSelectProducts();
            }).catch((error) => {
              this.loadingSiteProducts[doc.id][site] = true;
              this.checkboxSiteProducts[doc.id][site] = false;
              console.error(error);
            });
          });
        });
      });
    }
    ,
    async getAvailableSites() {
      const sites = await firebase.firestore()
          .collection('configuration')
          .doc('sites')
          .get();

      this.availableSites = [];
      sites.data()['availableSites'].forEach((doc) => {
        if (!this.user.data['sites'].includes(doc)) return;
        this.availableSites.push(doc);
        this.totalCartBySite[doc] = 0;
        this.checkboxSelectAllproducts[doc] = false;
      });
    }
    ,
    async getPrices() {
      console.log('getPrices')
      for (const [key, value] of Object.entries(this.currentCart)) {
        for (const [key2, value2] of Object.entries(this.loadingSiteProducts[key])) {
          this.loadingSiteProducts[key][key2] = false;
        }
      }
    }
  }
  ,
  async mounted() {
    console.log('mounted')
    auth.onAuthStateChanged(async (user) => {
      if (user) {

        this.user = user;
        this.user.data = (await firebase.firestore()
            .collection('users')
            .doc(this.user.uid)
            .get()).data();

        await this.getAvailableSites();
        await this.getCart();
        await this.getPrices()

        const bookmarks = await firebase.firestore()
            .collection('users')
            .doc(user.uid)
            .collection('bookmarks').get();
        bookmarks.forEach((doc) => {
          this.userBookmarks.push(doc.id);
          doc.data()['product_ids'].forEach((product_id) => {
            this.starredProducts[product_id] = doc.id;
          });
        });
      }
    });
  }
}
</script>

<style>
body {
  font-family: sans-serif;
}

.img-product-cart {
  width: 50px !important;
  height: 50px !important;
  border-radius: 15px;
  background: var(--blanc-ffffff, #FFF);
}

.left-panel {
  float: left;
  width: 20%;
}

.cell-product {
  display: flex;
  align-items: center;
}

.va-table td {
  vertical-align: middle !important;
}


.ais-Pagination-item--selected .ais-Pagination-link {
  background-color: var(--va-primary) !important;
  color: white !important;
}

.trophy-icon {
  width: 14px;
  height: 14px;
  flex-shrink: 0;
}

.ais-Pagination-link {
  color: var(--va-primary) !important;
  border-color: var(--va-primary) !important;
}

.th-left {
  text-align: left !important;
  padding-bottom: 40px !important;
  font-size: 14px;
}

table {
  text-align: left;
  position: relative;
}

table tfoot {
  border-top: black 2px solid;
  position: sticky;
  background: var(--va-background-primary);
  inset-block-end: 0; /* "bottom" */

}

th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 8;
}

.va-checkbox__label {
  font-size: .625rem;
  letter-spacing: 0.6px;
  line-height: 1.2;
  font-weight: 700;
  text-transform: uppercase;
  color: currentColor;
}

.border-best-price {
  border: 2px solid var(--va-primary);
  border-radius: 5px;
  padding: 7px;
  margin-left: 1px;
  margin-top: 1px;
}


.va-checkbox__label {
  font-size: 0.85rem;
}


.price-cart {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.32px;
  text-decoration-line: underline;
}

.saving-text {
  color: var(--mauve-6246-dc, #6246DC);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.56px;
}

.congrats-text {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 39.2px */
  letter-spacing: 0.56px;
}

.congrats-sub {
  color: var(--gris-fonc-858585, #858585);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
}

.info-title {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
}

.info-content {
  color: var(--gris-fonc-858585, #858585);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
}

.plugin-title {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  text-align: center;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
  text-align: -webkit-center;

}

#remove-btn .va-button__content {
  color: var(--mauve-6246-dc, #6246DC) !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: 0.48px !important;
}

#btn-auto .va-button__content {
  color: var(--blanc-ffffff, #FFF) !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: 0.48px !important;
}

.compare-table-header {
  position: -webkit-sticky;
  position: sticky;
  top: 143px;
  z-index: 1;
  background: #fff;
}

.total-price {
  color: rgb(255, 171, 89);
  text-decoration-line: none !important;
  font-size: 15px !important;
  text-align: left !important;
  padding-right: 50px !important;
}

.warning-order {
  background-color: rgb(249 182 113);
  height: 51px;
  border-radius: 15px;
  text-align: center;
  display: table;
  width: 100%;
}

.site-order {
  margin-top: 10px;
  background-color: rgb(161 148 221);
  height: 31px;
  border-radius: 15px;
  text-align: center;
  display: table;
  width: 100%;
}

.fix-action-button {
  position: -webkit-sticky;
  position: sticky;
  top: 78px;
  z-index: 1;
  height: 65px;
}
</style>

import 'bootstrap/dist/css/bootstrap.css'
import {createApp} from 'vue'
import {createI18n} from 'vue-i18n';
import App from '@/App.vue'
import router from '@/router'
import {setup as setupFirebase, auth as authFirebase} from '@/services/auth'
import {createVuestic} from 'vuestic-ui'
import 'vuestic-ui/css'
import './overrides.css'
import './common.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import axios from 'axios'
import firebase from "firebase/compat/app";
import InstantSearch from 'vue-instantsearch/vue3/es';

setupFirebase();

export const auth = authFirebase;
const i18n = createI18n({
    locale: 'fr', // Default lang
    legacy: false,
    messages: {
        fr: require('./assets/i18n/fr.json'),
        en: require('./assets/i18n/en.json'),
    },
});

const app = createApp(App);

app.use(router);
app.use(i18n);
app.use(InstantSearch);
app.use(createVuestic(
    {
        config: {
            colors: {
                presets: {
                    light: {
                        primary: '#6246DC',
                        secondary: '#FFAB59',
                        success: '#4CAF50',
                        info: '#2196F3',
                        warning: '#FB8C00',
                        danger: '#FF5252',
                        black: '#000000',
                        backgroundPrimary: '#F7F6FD',
                        backgroundSecondary: '#FFFFFF',
                        buttonSecondary: '#F7F6FD',
                    }
                }
            },
        },
    }
));
app.mount('#app');

axios.interceptors.request.use(async config => {
    //if request is to our backend, add authorization header
    if (config.url?.startsWith(process.env.VUE_APP_BACKEND_URL)) {
        const token = await firebase.auth().currentUser?.getIdToken();
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

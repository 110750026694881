import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import {onAuthStateChanged} from 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const auth = firebaseApp.auth();

function setup() {
    // Initialize any auth state observers or other auth setup code here
    // For example, you might setup an onAuthStateChanged observer
    // auth.onAuthStateChanged(user => {
    //   // Handle auth state change here
    // });
}

export {
    setup,
    auth
}
let currentUser;
// Keep track of the auth initialization process
let authReady = false;

onAuthStateChanged(auth, user => {
    // Update the authReady flag when Firebase has finished initializing
    authReady = true;
});

// Create a new Promise that waits for Firebase to finish initializing
export const waitForAuthReady = () => {
    return new Promise(resolve => {
        const checkInterval = setInterval(() => {
            if (authReady) {
                clearInterval(checkInterval);
                resolve(true);
            }
        }, 100);
    });
};

onAuthStateChanged(auth, (user) => {
    currentUser = user;
});

// Firebase Firestore fetch user data
async function fetchUserData(userId) {
    const docSnap = await firebase.firestore()
        .collection('users')
        .doc(userId).get();

    if (docSnap.data()) {
        return docSnap.data();
    } else {
        console.log('No such document!');
        return null;
    }
}


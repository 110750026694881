
import {defineComponent} from 'vue'
import {useRouter} from "vue-router";
import {auth} from "@/main";


export default defineComponent({
  components: {},
  setup() {
    const router = useRouter(); // get a reference to our vue router

    return {
      router,
    };
  },
  data() {
    return {
      user: auth.currentUser,
    }
  },
  methods: {
    async UpdateUserAndContinue() {
      await auth.currentUser?.reload();
      this.user = auth.currentUser;
      if (this.user?.emailVerified) {
        this.router.push('/confirm-account');
      }
    },
    async sendEmailConfirmation() {
      await auth.currentUser?.sendEmailVerification();
    },

    handleLoggedIn(user) {
      console.log('User has logged in: ', user);
      this.router.push('/search');
    },
  }
})

<template>
  <va-modal v-model="showModal" size="medium" blur hide-default-actions close-button ref="showModal">
    <h3 class="custom-h3">
      Favoris
    </h3>
    <p class="fav-desc">Veuillez sélectionner la liste dans laquelle enregistrer votre favoris.</p>
    <div>
      <table class="va-table va-table--clickable .va-table--hoverable " style="width: 100%">
        <thead>
        <tr>
          <th class="table-fav"></th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="bookmark in userBookmarks"
            :key="bookmark"
            @click="saveToBookmarks(bookmark)">
          <td>
            <va-icon name="radio_button_unchecked" color="warning" class="mr-2 pointer"></va-icon>
            <span class="fav-name">{{ bookmark }}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div>
      <va-button class="mt-4 btn-create-fav" color="secondary" @click="showModalNew=true">Créer une liste</va-button>
    </div>
  </va-modal>

    <va-modal v-model="showModalNew" blur size="small" close-button hide-default-actions
              backgroundColor="background-secondary">
      <h3 class="fav-name">
        Créer une liste
      </h3>
      <p>
        <va-input v-model="newBookmarkName" label="Nom de la liste"/>
      </p>
      <div style="text-align: center;">
        <va-button class="mt-3 btn-create-fav" @click="saveBookmark" color="primary">Sauvegarder</va-button>
      </div>
    </va-modal>

</template>

<script>
import firebase from "firebase/compat/app";
import {auth} from "@/services/auth";

export default {
  props: ['productToSave', 'userBookmarks', 'starredProducts'],
  data() {
    return {
      showModal: false,
      showModalNew: false,
      newBookmarkName: '',
    };
  },
  methods: {
    async saveBookmark() {
      await firebase.firestore()
          .collection('users')
          .doc(auth.currentUser.uid)
          .collection('bookmarks')
          .doc(this.newBookmarkName)
          .set({
            product_ids: [],
          });
      this.$emit('productFavorited', this.newBookmarkName);

      this.showModalNew = false;
      await this.saveToBookmarks(this.newBookmarkName);
      this.newBookmarkName = '';

    },
    async saveToBookmarks(bookmark) {
      await firebase.firestore()
          .collection('users')
          .doc(auth.currentUser.uid)
          .collection('bookmarks')
          .doc(bookmark)
          .update({
            product_ids: firebase.firestore.FieldValue.arrayUnion(this.productToSave),
          });
      this.$emit('productFavorited', bookmark);
      // this.starredProducts[this.productToSave] = bookmark;
      this.showModal = false;
      this.$vaToast.init({message: 'Produit ajouté aux favoris', position: 'bottom-right'})
    },
  },
};
</script>

<style>
.fav-name {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fav-desc {
  color: var(--gris-fonc-858585, #858585);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}


.btn-create-fav {
  display: flex;
  width: 246px;
  height: 40px;
  padding: 20px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--blanc-ffffff, #FFF) !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
  border-radius: 60px !important;
  background: var(--orange-ffab-59, #FFAB59) !important;
}

.custom-h3 {
  color: var(--noir-0-e-0-e-0-e, #0E0E0E);
  font-family: Montserrat;
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.42px;
}

.table-fav {
  background: #fff;
  top: 0 !important;
}

.va-modal__dialog {
  border-radius: 30px !important;
}

</style>